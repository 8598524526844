import React from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Slide,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {
  formatMoney,
  getOrderNumberFromdate,
  paymentChannels,
} from "../../utils/constant";
import UserIcon from "@heroicons/react/24/outline/UserIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import CalendarDaysIcon from "@heroicons/react/24/outline/CalendarDaysIcon";
import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";
import { useLocation } from "react-router-dom";
import {
  postRequest,
  webGetRequest,
} from "../../services/api-service";
import {
  getPaymentOrderStatusUrl,
} from "../../seed/url";
import { CustomAlert } from "../../components/custom-alert";
import CreditCardIcon from "@heroicons/react/24/outline/CreditCardIcon";
import TruckIcon from "@heroicons/react/24/outline/TruckIcon";
import { useAuth } from "../../hooks/use-auth";
import { formatDate } from "../../utils/date-formatter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewOrder({ open, handleClose, data, url }) {
  const auth = useAuth();
  const router = useLocation();
  const { pathname } = router;
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [shortDescription, setShortDescription] = React.useState("");
  const [channel, setChannel] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [severityMessage, setSeverityMessage] = React.useState("");
  const [orderPaymentDetails, setOrderPaymentDetails] = React.useState({});

  const updateOrderStatus = () => {
    if (!isSubmitting) {
      setSubmitting(true);
      postRequest(
        url,
        {
          id: data?.id,
          user_id: auth?.user?.id,
          status: data?.order_status,
          short_description: shortDescription,
          channel: channel + (accountName)
        },
        (data) => {
          setSeverityMessage(data.message);
          setSeverity("success");
          handleClickAlert();
          setShortDescription("");
          setSubmitting(false);
          handleClose();
        },
        (error) => {
          if (error?.response?.data?.message) {
            setSeverityMessage(error.response.data.message[0]);
            setSeverity("error");
            handleClickAlert();
          }
          setSubmitting(false);
        }
      );
    }
  };

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    if (data?.payment_method === "ONLINE") {
      setIsLoading(true);
      const orderId = data?.order_id?.split("[")
      webGetRequest(
        `${getPaymentOrderStatusUrl}${orderId[0] || ""}`,
        (data) => {
          setOrderPaymentDetails(data);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      aria-describedby="data-feed-form-dialog"
      fullWidth={true}
      maxWidth={"xl"}
    >
      {openAlert && (
        <CustomAlert
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          severity={severity}
          severityMessage={severityMessage}
        />
      )}
      <DialogActions>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          disabled={isSubmitting}
          onClick={() => {
            handleClose();
          }}
        >
          <SvgIcon fontSize="small">
            <XMarkIcon />
          </SvgIcon>
        </IconButton>
      </DialogActions>
      <DialogTitle>
        Information For Order No.{" "}
        {data?.id + getOrderNumberFromdate(data?.created_at)}
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress
              sx={{
                mx: "auto",
              }}
            />
          </Box>
        )}
        {!isLoading && (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} lg={pathname !== "/vendor/orders" && data?.payment_status !== "COMPLETED" ? 7 : 12}>
              <Box sx={{ display: "flex", mb: 1 }}>
                <SvgIcon fontSize="small">
                  <UserIcon />
                </SvgIcon>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  Full Name: {data?.full_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 1 }}>
                <SvgIcon fontSize="small">
                  <MapPinIcon />
                </SvgIcon>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  Shipping Address:{" "}
                  {`${data?.region}, ${data?.district}, ${data?.ward} ${data?.ward !== "" ? "," : ""} ${data?.street}`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 1 }}>
                <SvgIcon fontSize="small">
                  <PhoneIcon />
                </SvgIcon>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  Phone Number: {data?.phone_number}
                </Typography>
              </Box>
              {data?.delivery_day &&
                <Box sx={{ display: "flex", mb: 1 }}>
                  <SvgIcon fontSize="small">
                    <CalendarDaysIcon />
                  </SvgIcon>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    Date: {formatDate(data?.created_at)}
                  </Typography>
                </Box>
              }
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1
                }}
              >
                <SvgIcon fontSize="small">
                  <TruckIcon />
                </SvgIcon>
                <Typography variant="body1" sx={{ mx: 2 }}>
                  Order Status:
                </Typography>
                <Chip
                  color={
                    data?.order_status ===
                      "DELIVERED"
                      ? "success"
                      : "warning"
                  }
                  label={data?.order_status}
                  sx={{
                    width: 110,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1
                }}
              >
                <SvgIcon fontSize="small">
                  <BanknotesIcon />
                </SvgIcon>
                <Typography variant="body1" sx={{ mx: 2 }}>
                  Payment Status:
                </Typography>
                <Chip
                  color={
                    data?.payment_status ===
                      "COMPLETED"
                      ? "success"
                      : "warning"
                  }
                  label={data?.payment_status}
                  sx={{
                    width: 110,
                  }}
                />
              </Box>
              {data?.payment_method === "ONLINE" && pathname !== "/vendor/orders" &&
                orderPaymentDetails?.data && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <CreditCardIcon />
                    </SvgIcon>
                    <Typography variant="body1" sx={{ mx: 2 }}>
                      Selcom Status:
                    </Typography>
                    <Chip
                      color={
                        orderPaymentDetails?.data[0]?.payment_status ===
                          "COMPLETED"
                          ? "success"
                          : "warning"
                      }
                      label={orderPaymentDetails?.data[0]?.payment_status}
                      sx={{
                        width: 110,
                      }}
                    />
                  </Box>
                )
              }
              {(data?.products || data?.product_name) && (
                <TableContainer>
                  <Toolbar disableGutters>
                    <Typography variant="h6" component="div">
                      Products
                    </Typography>
                  </Toolbar>
                  <Table>
                    <TableBody>
                      {data?.products &&
                        data.products.map((product, index) => {
                          return (
                            <React.Fragment key={index}>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    border: "0.5px solid grey",
                                  }}
                                >
                                  <Typography variant="body1">
                                    Product Vendor Name
                                  </Typography>
                                  <Typography variant="body1">
                                    Product Name
                                  </Typography>
                                  <Typography variant="body1">
                                    Product Quantity
                                  </Typography>
                                  <Typography variant="body1">
                                    Product Amount
                                  </Typography>
                                  <Typography variant="body1">
                                    Product Shipping Cost
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "0.5px solid grey",
                                  }}
                                >
                                  <Typography variant="body1">
                                    {product?.product_vendor_name}
                                  </Typography>
                                  <Typography variant="body1">
                                    {product?.product_name}
                                  </Typography>
                                  <Typography variant="body1">
                                    {product?.product_quantity}
                                  </Typography>
                                  <Typography variant="body1">
                                    {formatMoney(product?.product_amount)}
                                  </Typography>
                                  <Typography variant="body1">
                                    {formatMoney(product?.shipping_cost)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "0.5px solid grey",
                                  }}
                                >
                                  <Avatar
                                    variant="rounded"
                                    alt="Preview Picture"
                                    src={
                                      data?.products?.[index]?.product_image
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })
                      }
                      {data?.product_name &&
                        <TableRow>
                          <TableCell
                            sx={{
                              border: "0.5px solid grey",
                            }}
                          >
                            <Typography variant="body1">
                              Product Name
                            </Typography>
                            <Typography variant="body1">
                              Product Quantity
                            </Typography>
                            <Typography variant="body1">
                              Product Amount
                            </Typography>
                            <Typography variant="body1">
                              Product Shipping Cost
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "0.5px solid grey",
                            }}
                          >
                            <Typography variant="body1">
                              {data?.product_name}
                            </Typography>
                            <Typography variant="body1">
                              {data?.product_quantity}
                            </Typography>
                            <Typography variant="body1">
                              {formatMoney(data?.discount_amount)}
                            </Typography>
                            <Typography variant="body1">
                              {formatMoney(data?.shipping_cost)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {data?.cart && (
                <Box sx={{ mt: 5 }}>
                  <Typography variant="h6">Products</Typography>
                  <Typography>{data?.cart} [{data?.product_quantity}]</Typography>
                  <Typography sx={{ my: 1 }}>
                    Vendor Name: {data?.product_vendor_name}
                  </Typography>
                </Box>
              )}
              <Typography sx={{ my: 1 }}>
                Total Product Cost: {formatMoney(data?.product_name ? data?.discount_amount : data?.product_amount)}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                Shipping Cost: {formatMoney(data?.shipping_cost)}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                Return Cost: {formatMoney(data?.return_amount)}
              </Typography>
              <Typography>
                Total Cost:{" "}
                {formatMoney((data?.product_name ? data?.discount_amount : data?.product_amount) + data?.shipping_cost)}
              </Typography>
              {data?.payment_tracking?.length > 0 && pathname !== "/vendor/orders" && (
                <>
                  <Typography variant="h6" sx={{ mt: 5 }}>
                    Payment Transactions
                  </Typography>
                  <List sx={{ width: "100%" }}>
                    {data?.payment_tracking.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ListItem
                            disableGutters
                            alignItems="flex-start"
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <Chip
                                  color={
                                    item.payment_status === "COMPLETED"
                                      ? "success"
                                      : "warning"
                                  }
                                  label={item.payment_status}
                                  sx={{
                                    width: 110,
                                  }}
                                />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              primary={
                                item.order_status === "NEEDTOCALLAGAIN"
                                  ? "NEED TO CALL AGAIN"
                                  : item.order_status
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {item.full_name}
                                  </Typography>
                                  {` - ${item.amount}`}
                                  <br />
                                  {item.created_at}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </React.Fragment>
                      );
                    })}
                  </List>
                  <Box sx={{ display: "flex", mb: 1 }}>
                    <SvgIcon fontSize="small">
                      <BanknotesIcon />
                    </SvgIcon>
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      Total Completed Amount:{" "}
                      {formatMoney(data?.total_completed_amount)}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            {pathname !== "/vendor/orders" && data?.payment_status !== "COMPLETED" &&
              <Grid item sm={12} xs={12} lg={5}>
                <TextField
                  multiline
                  type="text"
                  label={"Reference Number"}
                  margin="normal"
                  fullWidth
                  value={shortDescription}
                  onChange={(event) => {
                    setShortDescription(event.target.value);
                  }}
                />
                <TextField
                  multiline
                  type="text"
                  label={"Account Name"}
                  margin="normal"
                  fullWidth
                  value={accountName}
                  onChange={(event) => {
                    setAccountName(event.target.value);
                  }}
                />
                <TextField
                  select
                  margin="normal"
                  label={"Channel"}
                  value={channel}
                  onChange={(event) => {
                    setChannel(event.target.value);
                  }}
                  fullWidth
                >
                  {paymentChannels.map((status) => {
                    return (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            }
          </Grid>
        )}
      </DialogContent>
      {pathname !== "/vendor/orders" && data?.payment_status !== "COMPLETED" &&
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            onClick={updateOrderStatus}
          >
            {isSubmitting ? "Loading..." : `Submit`}
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default ViewOrder;
