import React from 'react';
import { Box } from '@mui/material';

function QRCodeComponent({ value }) {
    return (
        <Box sx={{ mr: 2, py: 2 }}>
            <img src={`https://api.qrserver.com/v1/create-qr-code/?size=50x50&data=${encodeURIComponent(value)}`} alt="QR Code" />
        </Box>
    );
}

export default QRCodeComponent;
