// assets
import {
  TruckOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  TruckOutlined,
  ShoppingCartOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const orders = {
  id: 'orders',
  title: 'Orders',
  type: 'group',
  children: [
    {
      id: 'vendor-track-orders',
      title: 'Tracking Orders',
      type: 'item',
      url: '/vendor/track-orders',
      icon: icons.TruckOutlined
    },
    {
      id: 'vendor-import-orders',
      title: 'Import Orders',
      type: 'item',
      url: '/vendor/import-orders',
      icon: icons.ShoppingCartOutlined
    }
  ]
};

export default orders;
