//LIVE SERVER BASE URLs
export const contentsUrl = "https://www.content.afyachap.com"
export const doctorsUrl = "https://www.doctors.afyachap.com"
export const usersUrl = "https://www.users.afyachap.com"
export const webUrl = "https://afyachap.com"

//TEST SERVER BASE URLs
// export const contentsUrl = "https://www.testserver.content.afyachap.com"
// export const doctorsUrl = "https://www.testserver.doctors.afyachap.com"
// export const usersUrl = "https://www.testserver.users.afyachap.com"

//LOCAL SERVER BASE URLs
// export const contentsUrl = "http://192.168.1.172:8004"
// export const doctorsUrl = "http://192.168.1.172:8001"
// export const usersUrl = "http://192.168.1.172:8003"

//USER MANAGEMENT URLs
export const getUserUrl = `${usersUrl}/api/get/user`
export const getUserByIDUrl = `${usersUrl}/api/get/user/by/id`
export const loginByPhoneNumberUrl = `${usersUrl}/api/login/user/web/by/phonenumber`
export const loginByEmailUrl = `${usersUrl}/api/login/user/web/by/email`
export const verifyPhoneUrl = `${usersUrl}/api/verify/phone`
export const getAllUsersByRoleUrl = `${usersUrl}/api/get/all/users/by/role/by/pagination`
export const getSpecificUserNotificationsUrl = `${usersUrl}/api/get/specific/user/notifications/by/pagination`
export const getAllVendorsUrl = `${usersUrl}/api/all/vendors/by/pagination`
export const registerUserByRoleUrl = `${usersUrl}/api/register/user/by/role`
export const updateUserByRoleUrl = `${usersUrl}/api/update/user/by/role`
export const enableDisableUserUrl = `${usersUrl}/api/enable/disable/user`
export const deleteAccountUrl = `${usersUrl}/api/delete/account`

/*Products Orders URLs */
export const getAllOrdersByPaginationUrl = `${usersUrl}/api/v1/get/all/orders/by/pagination`
export const getOrderUrl = `${usersUrl}/api/v1/get/products/order`
export const getOrderWithoutIDUrl = `${usersUrl}/api/v1/get/products/order/without/id`
export const updateOrderStatusUrl = `${usersUrl}/api/v1/update/order/status`
export const updateExternalOrderStatusUrl = `${usersUrl}/api/v1/update/external/order/status`
export const updateOrderPaymentStatusUrl = `${usersUrl}/api/v1/update/order/payment/status`
export const getProductsOrdersStatisticsUrl = `${usersUrl}/api/v1/get/products/orders/statistics`
export const getExternalProductsOrdersStatisticsUrl = `${usersUrl}/api/v1/get/external/products/orders/statistics`
export const getProductsOrdersStatisticsForVendorUrl = `${usersUrl}/api/v1/get/products/orders/statistics/for/vendor`
export const getProductsOrdersTopRegionsUrl = `${usersUrl}/api/v1/get/products/orders/top/regions`
export const getExternalProductsOrdersTopRegionsUrl = `${usersUrl}/api/v1/get/external/products/orders/top/regions`
export const getProductsOrdersTopRegionsForVendorUrl = `${usersUrl}/api/v1/get/products/orders/top/regions/for/vendor`
export const getVendorCheckoutBalanceUrl = `${usersUrl}/api/v1/get/checkout/balance/for/vendor`
export const getProductsOrdersInvoicesStatisticsUrl = `${usersUrl}/api/v1/get/products/orders/invoices/statistics`
export const getPaymentOrderStatusUrl = `${webUrl}/api/v1/get/payment/order/status/`
export const productOrderMakePaymentUrl = `${usersUrl}/api/v1/product/order/make/payment`
export const productOrderVerifyPaymentDetailsUrl = `${usersUrl}/api/v1/product/order/verify/payment/details`

/*Web Products Orders URLs */
export const getAllWebOrdersByPaginationUrl = `${usersUrl}/api/v1/get/all/web/orders/by/pagination`
export const updateWebOrderStatusUrl = `${usersUrl}/api/v1/update/web/order/status`
export const updateWebOrderPaymentStatusUrl = `${usersUrl}/api/v1/update/web/order/payment/status`
export const webOrderMakePaymentUrl = `${usersUrl}/api/v1/web/order/make/payment`
export const webOrderVerifyPaymentDetailsUrl = `${usersUrl}/api/v1/web/order/verify/transaction/details`

/*External Products Orders URLs */
export const createExternalOrderUrl = `${usersUrl}/api/v1/create/external/order`
export const getAllExternalOrdersByPaginationUrl = `${usersUrl}/api/v1/get/all/external/orders/by/pagination`
export const updateExternalOrderUrl = `${usersUrl}/api/v1/update/external/order`
export const updateExternalOrderPaymentStatusUrl = `${usersUrl}/api/v1/update/external/order/payment/status`
export const deleteExternalOrderUrl = `${usersUrl}/api/v1/delete/external/order`
export const externalOrderMakePaymentUrl = `${usersUrl}/api/v1/external/order/make/payment`
export const externalOrderVerifyPaymentDetailsUrl = `${usersUrl}/api/v1/external/order/verify/payment/details`

/*Vendor Expenses URLs */
export const createVendorExpensesUrl = `${usersUrl}/api/v1/add/vendor/expenses`
export const getAllVendorExpensesUrl = `${usersUrl}/api/v1/get/all/vendor/expenses`
export const getAllVendorExpensesByPaginationUrl = `${usersUrl}/api/v1/get/all/vendor/expenses/by/pagination`

/*Products Transport Payments URLs */
export const createProductsTransportsPaymentsUrl = `${usersUrl}/api/v1/create/products/transports/payments`
export const getAllProductsTransportsPaymentsByPaginationUrl = `${usersUrl}/api/v1/get/all/products/transports/payments/by/pagination`

/*Products Orders Invoices URLs */
export const createOrderInvoiceUrl = `${usersUrl}/api/v1/create/products/orders/invoices`
export const createExternalOrderInvoiceUrl = `${usersUrl}/api/v1/create/external/products/orders/invoices`
export const getAllOrdersInvoicesByPaginationUrl = `${usersUrl}/api/v1/get/products/orders/invoices/by/pagination`
export const getAllExternalOrdersInvoicesByPaginationUrl = `${usersUrl}/api/v1/get/external/products/orders/invoices/by/pagination`

/*Products URLs */
export const createProductUrl = `${usersUrl}/api/v1/create/external/product`
export const getAllProductsUrl = `${usersUrl}/api/v1/get/external/products/by/pagination`
export const updateProductUrl = `${usersUrl}/api/v1/update/external/product`
export const deleteProductUrl = `${usersUrl}/api/v1/delete/external/product`
export const addProductYouTubeVideoUrl = `${usersUrl}/api/v1/add/external/product/youtube/video`
export const addProductImageUrl = `${usersUrl}/api/v1/add/external/product/image`
export const getProductUrl = `${usersUrl}/api/v1/get/external/product`
export const deleteProductImageUrl = `${usersUrl}/api/v1/delete/external/product/image`

/*Regions URLs */
export const getAllRegionsUrl = `${usersUrl}/api/v1/get/all/regions`

/*Districts URLs */
export const getAllDistrictsUrl = `${usersUrl}/api/v1/get/all/districts`

/*Wards URLs */
export const getAllWardsUrl = `${usersUrl}/api/v1/get/all/wards`