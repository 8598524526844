import React from 'react'
import { TablePagination, Typography } from '@mui/material'
import TransactionHistory from '../../components/TransactionHistory'
import { authPostRequest } from '../../services/api-service';
import { useAuth } from '../../hooks/use-auth';

function TransactionsHistory() {
    const auth = useAuth();
    const [transactionHistory, setTransactionHistory] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: [],
    });
    const [isLoading, setIsLoading] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true);
            authPostRequest(
                "",
                {
                    user_id: auth?.user?.id,
                    sort: "id desc",
                    limit: rowsPerPage,
                    page: page,
                },
                (data) => {
                    setTransactionHistory(data);
                    setIsLoading(false);
                },
                (error) => {
                    setTransactionHistory({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    });
                    setIsLoading(false);
                }
            );
        },
        [rowsPerPage, auth]
    );

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1);
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    React.useEffect(() => {
        fetcher(1);
    }, [fetcher]);

    return (
        <>
            <Typography variant="h5">Transaction History</Typography>
            {transactionHistory.results.length === 0 && !isLoading &&
                <Typography
                    sx={{ my: 3, }}
                    align='center'
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    No Transaction History
                </Typography>
            }
            <TransactionHistory
                data={transactionHistory.results}
            />
            {transactionHistory.results.length > 0 && !isLoading &&
                <TablePagination
                    component="div"
                    count={transactionHistory.total_results}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={
                        transactionHistory.page >= 1
                            ? transactionHistory.page - 1
                            : transactionHistory.page
                    }
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            }
        </>
    )
}

export default TransactionsHistory