import React from 'react'
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useSelection } from '../../hooks/use-selection';
import { CustomTable } from '../../components/custom-table';
import { CustomSearch } from '../../components/custom-search';
import { paymentsVerificationHeadCells } from '../../seed/table-headers';
import { allTimeTimeRange, convertArrayOfProductToString, lastSevenDaysTimeRange, orderPaymentStatus, orderStatusForPaymentVerification, ordersBasedOnPlatform, thisMonthTimeRange, todayTimeRange, yesterdayTimeRange } from '../../utils/constant';
import { postRequest } from '../../services/api-service';
import { getAllOrdersByPaginationUrl, getAllWebOrdersByPaginationUrl, updateOrderPaymentStatusUrl, updateWebOrderPaymentStatusUrl } from '../../seed/url';
import EyeIcon from '@heroicons/react/24/outline/EyeIcon';
import { useDispatch, useSelector } from 'react-redux';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import { usePopover } from '../../hooks/use-popover';
import dayjs from 'dayjs'
import { utils, writeFile } from "xlsx";
import FilterOrderPopper from '../Orders/FilterOrderPopper';
import ViewOrder from './ViewOrder';
import { CustomTabs } from '../../components/CustomTabs';
import { formatDateForExcel } from '../../utils/date-formatter';
import { useAuth } from '../../hooks/use-auth';
import { CustomPopOver } from '../../components/custom-popover';
import CircleStackIcon from '@heroicons/react/24/outline/CircleStackIcon';
import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon';

const useProductsOrdersIds = (orders) => {
    return React.useMemo(
        () => {
            return orders.map((customer) => customer.id);
        },
        [orders]
    );
};

export const handleExport = (data) => {
    if (data.length > 0) {
        const newData = data.map((row, index) => {
            const newRow = {
                "S/No": index + 1,
                "Order ID": row?.order_id,
                "Full Name": row?.full_name,
                "Region": row?.region,
                "District": row?.district,
                "Street": row?.street,
                "Phone Number": row?.phone_number,
                "Products": convertArrayOfProductToString(row?.products),
                "Order Status": row?.order_status,
                "Payment Status": row?.payment_status,
                "Product Amount": row?.product_amount,
                "Shipping Cost": row?.shipping_cost,
                "Date": formatDateForExcel(row?.created_at),
            };
            return newRow;
        });
        data = newData;
        let headings = Object.keys(data[0]);
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, [headings]);
        utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
        utils.book_append_sheet(wb, ws, "Orders");
        writeFile(wb, `Orders downloaded at ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`);
    }
};

function OrdersPaymentVerification() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const popOver = usePopover();
    const orderStatusPopOver = usePopover();
    const [currentTab, setCurrentTab] = React.useState({ label: "All", value: "" });
    const [activeTab, setActiveTab] = React.useState(ordersBasedOnPlatform[0].value);
    const orderSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);
    const [openViewDialog, setOpenViewDialog] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [productsOrders, setProductsOrders] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: []
    });
    const [selectedData, setSelectedData] = React.useState({})
    const [filterItemsData, setFilterItemsData] = React.useState({
        location: "",
        order_status: "",
        payment_status: ""
    })
    const [searchTerm, setSearchTerm] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [exportExcel, setExportExcel] = React.useState(false)
    const productsIds = useProductsOrdersIds(productsOrders.results);
    const productsOrdersSelection = useSelection(productsIds);
    const [timeRange, setTimeRange] = React.useState({
        "from": dayjs('1970-01-01T00:00:00Z').format('YYYY-MM-DD HH:mm:ss.SSS'),
        "to": dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    })
    const filterItems = [
        {
            label: "Today",
            onClick: () => {
                setTimeRange(todayTimeRange)
            }
        },
        {
            label: "Yesterday",
            onClick: () => {
                setTimeRange(yesterdayTimeRange)
            }
        },
        {
            label: "Last 7 days",
            onClick: () => {
                setTimeRange(lastSevenDaysTimeRange)
            }
        },
        {
            label: "This month",
            onClick: () => {
                setTimeRange(thisMonthTimeRange)
            }
        },
        {
            label: "All time",
            onClick: () => {
                setTimeRange(allTimeTimeRange)
            }
        },
    ]
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getDataForExportExcel = () => {
        setExportExcel(true)
        postRequest(
            activeTab === ordersBasedOnPlatform[0].value ? getAllOrdersByPaginationUrl : getAllWebOrdersByPaginationUrl,
            {
                query: `${searchTerm}${filterItemsData.location === "" || searchTerm === "" ? "" : " " + filterItemsData.location}${filterItemsData.order_status === "" ? "" : " " + filterItemsData.order_status}${filterItemsData.payment_status === "" ? "" : " " + filterItemsData.payment_status}`,
                status: currentTab.value,
                from: timeRange.from,
                to: timeRange.to,
                sort: orderBy + " " + order,
                limit: productsOrders.total_results,
                page: 1
            },
            (data) => {
                handleExport(data?.results)
                setExportExcel(false)
            },
            (error) => {
                setExportExcel(false)
            },
        )
    }

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true)
            postRequest(
                activeTab === ordersBasedOnPlatform[0].value ? getAllOrdersByPaginationUrl : getAllWebOrdersByPaginationUrl,
                {
                    query: `${searchTerm}${filterItemsData.location === "" || searchTerm === "" ? "" : " " + filterItemsData.location}${filterItemsData.order_status === "" ? "" : " " + filterItemsData.order_status}${filterItemsData.payment_status === "" ? "" : " " + filterItemsData.payment_status}`,
                    status: currentTab.value,
                    from: timeRange.from,
                    to: timeRange.to,
                    sort: orderBy + " " + order,
                    limit: rowsPerPage,
                    page: page
                },
                (data) => {
                    setProductsOrders(data)
                    setIsLoading(false)
                },
                (error) => {
                    setProductsOrders({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    })
                    setIsLoading(false)
                },
            )
        },
        [rowsPerPage, searchTerm, currentTab, filterItemsData, timeRange, orderBy, order, activeTab]
    );

    const confirmSelectedOrderPaymentStatus = () => {
        productsOrdersSelection.selected.forEach((id, index) => {
            postRequest(
                activeTab === ordersBasedOnPlatform[0].value ? updateOrderPaymentStatusUrl : updateWebOrderPaymentStatusUrl,
                {
                    id: id,
                    user_id: auth?.user?.id
                },
                (data) => {
                    fetcher(productsOrders.page)
                },
                (error) => {
                    fetcher(productsOrders.page)
                },
            )
            if (productsOrdersSelection.selected.length === index + 1) {
                productsOrdersSelection.handleDeselectAll()
            }
        })
    }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
    }

    React.useEffect(() => {
        fetcher(1)
    }, [fetcher])

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1)
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback(
        (event) => {
            setRowsPerPage(event.target.value);
        },
        []
    );

    const handleClickOpenViewDialog = () => {
        setOpenViewDialog(true)
    }

    const handleCloseViewDialog = () => {
        fetcher(productsOrders.page)
        setOpenViewDialog(false)
    }

    const onSelect = (data, openDialog) => {
        setSelectedData(data)
        openDialog && handleClickOpenViewDialog()
    }

    const orderPopoverItems = [
        {
            id: 'track_order',
            label: 'Track Order',
            icon: <SvgIcon fontSize="small" sx={{ color: "text.primary" }}><EyeIcon /></SvgIcon>,
            onClick: () => {
                dispatch({
                    type: "TOOGLE_PAYMENT_SIDENAV",
                    payload: {
                        ...orderSideNav,
                        openViewOrderStatusTrackingSideNav: true,
                        orderStatusTrackingSideNavContent: selectedData
                    },
                });
            },
        }
    ]

    return (
        <>
            {orderStatusPopOver.open &&
                <CustomPopOver
                    id={orderStatusPopOver.id}
                    anchorEl={orderStatusPopOver.anchorRef}
                    open={orderStatusPopOver.open}
                    onClose={orderStatusPopOver.handleClose}
                    popoverItems={orderStatusForPaymentVerification.map(item => {
                        return {
                            id: item.value,
                            label: item.label,
                            icon: <SvgIcon fontSize="small" sx={{ color: "primary.main" }}><CircleStackIcon /></SvgIcon>,
                            onClick: () => { setCurrentTab({ label: item.label, value: item.value }) }
                        }
                    })}
                />
            }
            {popOver.open &&
                <FilterOrderPopper
                    id={popOver.id}
                    anchorEl={popOver.anchorRef}
                    open={popOver.open}
                    onClose={popOver.handleClose}
                    filterItemsData={filterItemsData}
                    setFilterItemsData={setFilterItemsData}
                />
            }
            {openViewDialog &&
                <ViewOrder
                    open={openViewDialog}
                    handleClose={handleCloseViewDialog}
                    data={selectedData}
                    url={activeTab === ordersBasedOnPlatform[0].value ? updateOrderPaymentStatusUrl : updateWebOrderPaymentStatusUrl}
                />
            }
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pt: 2,
                    pb: 8
                }}
            >
                <Container maxWidth={false}>
                    <Stack spacing={2}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Typography variant="h4">
                                    Payments Verification
                                </Typography>
                            </Stack>
                            <div>
                                <Button
                                    onClick={(event) => {
                                        popOver.handleOpen(event)
                                    }}
                                    endIcon={(
                                        <SvgIcon fontSize="small">
                                            <ChevronDownIcon />
                                        </SvgIcon>
                                    )}
                                    variant="contained"
                                    sx={{
                                        color: "neutral.100",
                                        m: 1
                                    }}
                                >
                                    Filter By
                                </Button>
                            </div>
                        </Stack>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                gap: 2,
                            }}
                        >
                            <Typography color="primary" variant='caption' sx={{ fontSize: 18 }}>Filter by Status</Typography>
                            <SvgIcon fontSize='small'>
                                <ChevronRightIcon />
                            </SvgIcon>
                            <Button
                                variant='outlined'
                                startIcon={
                                    <SvgIcon fontSize='small'>
                                        <CircleStackIcon />
                                    </SvgIcon>
                                }
                                endIcon={
                                    <SvgIcon fontSize='small'>
                                        <ChevronDownIcon />
                                    </SvgIcon>
                                }
                                sx={{ maxWidth: 500, justifyContent: "space-between" }}
                                onClick={(event) => orderStatusPopOver.handleOpen(event)}
                            >
                                {currentTab.label}
                            </Button>
                        </Box>
                        <CustomTabs
                            currentTab={activeTab}
                            setCurrentTab={setActiveTab}
                            tabsData={ordersBasedOnPlatform}
                            style={{}}
                            indicatorColor={"primary"}
                        />
                        <CustomSearch
                            popoverItems={filterItems}
                            handleSearch={handleSearch}
                            exportExcel={exportExcel}
                            getDataForExportExcel={getDataForExportExcel}
                            selectedItems={productsOrdersSelection}
                            confirmSelectedOrderStatus={confirmSelectedOrderPaymentStatus}
                            statusOfOrder={orderPaymentStatus.slice(0, 1)}
                        />
                        <CustomTable
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            count={productsOrders.total_results}
                            items={productsOrders.results}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelectOne={productsOrdersSelection.handleSelectOne}
                            onSelect={onSelect}
                            onSelectAll={productsOrdersSelection.handleSelectAll}
                            onDeselectOne={productsOrdersSelection.handleDeselectOne}
                            onDeselectAll={productsOrdersSelection.handleDeselectAll}
                            page={productsOrders.page >= 1 ? productsOrders.page - 1 : productsOrders.page}
                            rowsPerPage={rowsPerPage}
                            selected={productsOrdersSelection.selected}
                            headCells={activeTab === ordersBasedOnPlatform[0].value ? paymentsVerificationHeadCells : paymentsVerificationHeadCells.slice(0, 7).concat(paymentsVerificationHeadCells.slice(9, 13))}
                            popoverItems={orderPopoverItems}
                            isLoading={isLoading}
                        />
                    </Stack>
                </Container>
            </Box>
        </>
    )
}

export default OrdersPaymentVerification