import React from 'react'
import { Avatar, Box, Card, CardContent, CircularProgress, SvgIcon, Typography } from '@mui/material'
import { formatMoney } from '../../utils/constant'

function CustomCard({ data, isLoading }) {

    return (
        <Card
            elevation={1}
            sx={{
                height: "100%",
                cursor: "pointer"
            }}
            onClick={data.onClick}
        >
            <CardContent
                sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center"
                }}
            >
                <Avatar
                    sx={{
                        backgroundColor: (theme) => theme.palette.primary.lightest,
                        height: 60,
                        width: 60,
                    }}
                >
                    <SvgIcon fontSize="large" sx={{ color: "grey", fontSize: 30 }}>
                        {data.icon}
                    </SvgIcon>
                </Avatar>
                <Box
                    sx={{
                        ml: 3
                    }}
                >
                    <Typography variant='body2'><b>{data.name}:</b> [{data.value}]</Typography>
                    {!isLoading && <Typography variant='body2'><b>Product Cost:</b> [{formatMoney(data.productCost)}]</Typography>}
                    {!isLoading && <Typography variant='body2'><b>Shipping Cost:</b> [{formatMoney(data.shippingCost)}]</Typography>}
                    {isLoading &&
                        <CircularProgress
                            size={20}
                        />
                    }
                </Box>
            </CardContent>
        </Card>
    )
}

export default CustomCard