export const formatDate = (dateString) => {
    const options = {
        // weekday: 'long',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
};

export const formatDateUS = (dateString) => {
    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
};

export const formatDateForExcel = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB');

    return formattedDate;
};

export const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const convertedDate = `${month}/${day}/${year}`;
    return convertedDate;
};

export const getDaysAgo = (dateString) => {
    const today = new Date();
    const pastDate = new Date(dateString);
    const timeDifference = today.getTime() - pastDate.getTime();
    const daysAgo = Math.floor(timeDifference / (1000 * 3600 * 24));
    const hoursAgo = Math.floor(timeDifference / (1000 * 3600));
    if (daysAgo > 0) {
        return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    } else if (hoursAgo > 0) {
        return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
    } else {
        return 'Just now';
    }
};