import * as React from "react"
import { Avatar, Box, CircularProgress, Divider, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, TablePagination, Typography } from '@mui/material'
import { MessageOutlined } from '@ant-design/icons'
import { avatarSX, actionSX } from '../../layouts/VendorLayout/Header/HeaderContent/Notification'
import { authPostRequest } from '../../services/api-service';
import { useAuth } from '../../hooks/use-auth';
import { getSpecificUserNotificationsUrl } from '../../seed/url';
import { formatDateUS, getDaysAgo } from "../../utils/date-formatter";

function Notifications() {
    const auth = useAuth();
    const [notifications, setNotifications] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: [],
    });
    const [isLoading, setIsLoading] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true);
            authPostRequest(
                getSpecificUserNotificationsUrl,
                {
                    user_id: auth?.user?.id,
                    sort: "id desc",
                    limit: rowsPerPage,
                    page: page,
                },
                (data) => {
                    setNotifications(data);
                    setIsLoading(false);
                },
                (error) => {
                    setNotifications({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    });
                    setIsLoading(false);
                }
            );
        },
        [rowsPerPage, auth]
    );

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1);
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    React.useEffect(() => {
        fetcher(1);
    }, [fetcher]);

    return (
        <>
            <Typography variant="h5">Notifications</Typography>
            {notifications.results.length === 0 && isLoading &&
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <CircularProgress
                        sx={{
                            mx: 'auto',
                            my: 3,
                        }}
                    />
                </Box>
            }
            {notifications.results.length === 0 && !isLoading &&
                <Typography
                    sx={{ my: 3, }}
                    align='center'
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    No Notifications
                </Typography>
            }
            <List
                component="nav"
                sx={{
                    p: 0,
                    '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                }}
            >
                {notifications.results.map((item, index) => {

                    return (
                        <React.Fragment key={index}>
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: 'primary.main',
                                            bgcolor: 'primary.lighter'
                                        }}
                                    >
                                        <MessageOutlined />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="h6">
                                            {item.body}
                                        </Typography>
                                    }
                                    secondary={formatDateUS(item.created_at)}
                                />
                                <ListItemSecondaryAction>
                                    <Typography variant="caption" noWrap>
                                        {getDaysAgo(item.created_at)}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    );
                })}
            </List>
            {notifications.results.length > 0 && !isLoading &&
                <TablePagination
                    component="div"
                    count={notifications.total_results}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={
                        notifications.page >= 1
                            ? notifications.page - 1
                            : notifications.page
                    }
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            }
        </>
    )
}

export default Notifications