import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, Slide, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { formatMoney, getOrderNumberFromdate } from '../../utils/constant';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DownloadOrdersInvoices({
    open,
    handleClose,
    selectedData
}) {
    const totalProductsCost = (selectedData.total_products_cost_cash_on_delivery + selectedData.total_products_cost_cash_online_pending) -
        (selectedData.total_shipping_cost_cash_online_completed + selectedData.total_shipping_cost_cash_on_delivery + selectedData.total_shipping_cost_cash_online_pending + selectedData.total_returned_shipping_cost)
    const printRef = React.useRef();

    async function printInvoice() {
        await new Promise((resolve) => setTimeout(resolve, 2000));

        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("smai_invoice.pdf");
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="download-invoice-dialog"
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogContent ref={printRef}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <Avatar
                            variant='rounded'
                            alt="Logo"
                            src={"/assets/images/logo.png"}
                        />
                        <Typography
                            variant='h5'
                        >
                            SMAI GROUP OF COMPANIES
                        </Typography>
                    </Box>
                    <Typography
                        variant='h5'
                    >
                        Invoice
                    </Typography>
                </Box>
                <Typography
                    variant='body1'
                    textAlign="right"
                >
                    #{selectedData.id + getOrderNumberFromdate(selectedData.created_at)}
                </Typography>
                <Table
                    sx={{
                        "& th, & td": {
                            borderBottom: "none",
                        },
                        mt: 2
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Cash On Delivery Amount
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle1">{formatMoney(selectedData.total_products_cost_cash_on_delivery)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            Pending Online Amount
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle1">{formatMoney(selectedData.total_products_cost_cash_online_pending)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Online Paid Shipping Cost
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                <Typography variant="subtitle1">{formatMoney(selectedData.total_shipping_cost_cash_online_completed)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Cash On Delivery Shipping Amount
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                <Typography variant="subtitle1">{formatMoney(selectedData.total_shipping_cost_cash_on_delivery)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Shipping Amount Online Pending
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                <Typography variant="subtitle1">{formatMoney(selectedData.total_shipping_cost_cash_online_pending)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Returned Shipping Amount
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                <Typography variant="subtitle1">{formatMoney(selectedData.total_returned_shipping_cost)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Total Products Amount
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                <Typography variant="subtitle1">{formatMoney(totalProductsCost)}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Discard</Button>
                <Button onClick={printInvoice}>Download Invoice</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DownloadOrdersInvoices