export const ordersHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'order_number',
        label: 'Order Number',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'order_id',
        label: 'Transaction Reference',
    },
    {
        id: 'full_name',
        label: 'Customer Name',
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
    },
    {
        id: 'location',
        label: 'Location',
    },
    {
        id: 'payment_method',
        label: 'Payment Method',
    },
    {
        id: 'order_status',
        label: 'Order Status',
    },
    {
        id: 'payment_status',
        label: 'Payment Status',
    },
]

export const vendorOrdersHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'order_number',
        label: 'Order Number',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'full_name',
        label: 'Customer Name',
    },
    {
        id: 'location',
        label: 'Location',
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
    },
    {
        id: 'order_status',
        label: 'Order Status',
    },
]

export const paymentsVerificationHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'order_number',
        label: 'Order Number',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'order_id',
        label: 'Transaction Reference',
    },
    {
        id: 'full_name',
        label: 'Customer Name',
    },
    {
        id: 'location',
        label: 'Location',
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
    },
    {
        id: 'payment_method',
        label: 'Payment Method',
    },
    {
        id: 'order_status',
        label: 'Order Status',
    },
    {
        id: 'payment_status',
        label: 'Payment Status',
    },
]

export const externalOrdersHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'order_number',
        label: 'Order Number',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'order_id',
        label: 'Transaction Reference',
    },
    {
        id: 'product_vendor_name',
        label: 'Vendor Name',
    },
    {
        id: 'full_name',
        label: 'Customer Name',
    },
    {
        id: 'location',
        label: 'Location',
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
    },
    {
        id: 'order_status',
        label: 'Order Status',
    },
    {
        id: 'payment_status',
        label: 'Payment Status',
    },
]

export const externalVendorOrdersHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'order_number',
        label: 'Order Number',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'order_id',
        label: 'Transaction Reference',
    },
    {
        id: 'full_name',
        label: 'Customer Name',
    },
    {
        id: 'location',
        label: 'Location',
    },
    {
        id: 'phone_number',
        label: 'Phone Number',
    },
    {
        id: 'order_status',
        label: 'Order Status',
    },
    {
        id: 'payment_status',
        label: 'Payment Status',
    },
]

export const usersHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'profile_image',
        label: 'Profile',
    },
    {
        id: 'name',
        label: '',
    },
    {
        id: 'switch',
        label: '',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'phoneNumber',
        label: 'Phone number',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'gender',
        label: 'Gender',
    },
    {
        id: 'age',
        label: 'Age',
    },
    {
        id: 'createdAt',
        label: 'Date',
    },
]

export const vendorHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'userName',
        label: 'Name',
    },
    {
        id: 'switch',
        label: '',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'phoneNumber',
        label: 'Phone number',
    },
    {
        id: 'include_shipping_cost',
        label: 'Include Shipping Cost',
    },
    {
        id: 'inbound_recharge_amount',
        label: 'Inbound Recharge Amount',
    },
    {
        id: 'outbound_recharge_amount',
        label: 'Outbound Recharge Amount',
    },
    {
        id: 'inbound_return_amount',
        label: 'Inbound Return Amount',
    },
    {
        id: 'outbound_return_amount',
        label: 'Outbound Return Amount',
    },
    {
        id: 'account_type',
        label: 'Account Type',
    },
    {
        id: 'account_number',
        label: 'Account Number',
    },
    {
        id: 'createdAt',
        label: 'Date',
    },
]

export const productsTransportsPaymentsHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'short_description',
        label: 'Description',
    },
    {
        id: 'full_name',
        label: 'added By',
    },
    {
        id: 'image_url',
        label: 'Attachment',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const ordersInvoicesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'full_name',
        label: 'Created By',
    },
    {
        id: 'total_products_cost_cash_on_delivery',
        label: 'Products Cost Cash On Delivery',
    },
    {
        id: 'total_products_cost_cash_online_pending',
        label: 'Products Cost Cash Online Pending',
    },
    {
        id: 'total_shipping_cost_cash_online_completed',
        label: 'Shipping Cost Cash Online Completed',
    },
    {
        id: 'total_shipping_cost_cash_on_delivery',
        label: 'Shipping Cost On Delivery',
    },
    {
        id: 'total_shipping_cost_cash_online_pending',
        label: 'Shipping Cost Cash Online Pending',
    },
    {
        id: 'total_returned_shipping_cost',
        label: 'Returned Shipping Cost',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
]

export const externalOrdersInvoicesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'full_name',
        label: 'Created By',
    },
    {
        id: 'product_vendor_name',
        label: 'Vendor Name',
    },
    {
        id: 'total_products_cost',
        label: 'Products Cost',
    },
    {
        id: 'total_shipping_cost',
        label: 'Shipping Cost',
    },
    {
        id: 'total_returned_cost',
        label: 'Returned Cost',
    },
    {
        id: 'total_expenses',
        label: 'Total Expenses',
    },
    {
        id: 'invoice_number',
        label: 'Invoice Number',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
]

export const productsHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'product_name',
        label: 'Product Name',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'product_vendor_name',
        label: 'Vendor Name',
    },
    {
        id: 'product_quantity',
        label: 'Quantity',
    },
    {
        id: 'product_amount',
        label: 'selling Price',
    },
    {
        id: 'product_shipping_cost_in_dar',
        label: 'Inbound Shipping Cost',
    },
    {
        id: 'product_shipping_cost_in_other_regions',
        label: 'Outbound Shipping Cost',
    },
    {
        id: 'product_status',
        label: 'Status',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const vendorProductsHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'product_name',
        label: 'Product Name',
    },
    {
        id: 'actions',
        label: '',
    },
    {
        id: 'product_quantity',
        label: 'Quantity',
    },
    {
        id: 'product_amount',
        label: 'selling Price',
    },
    {
        id: 'product_shipping_cost_in_dar',
        label: 'Shipping Cost In Dar',
    },
    {
        id: 'product_shipping_cost_in_other_regions',
        label: 'Shipping Cost In Other Regions',
    },
    {
        id: 'product_status',
        label: 'Status',
    },
    {
        id: 'created_at',
        label: 'Date',
    },
]

export const vendorInvoicesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
    {
        id: 'full_name',
        label: 'Created By',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
]

export const expensesHeadCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'title',
        label: 'Title',
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'product_vendor_name',
        label: 'Vendor Name',
    },
    {
        id: 'full_name',
        label: 'Added By',
    },
    {
        id: 'placed_at',
        label: 'Date',
    },
]