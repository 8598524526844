import React from 'react';
import { Box, Drawer, IconButton, ImageList, ImageListItem, SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ViewProductSideNav = (props) => {
    const { open, onClose } = props;
    const productSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.100',
                    width: 300,
                }
            }}
            sx={{ zIndex: 1300 }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2,
                    borderBottom: "2px solid grey",
                }}
            >
                <Typography variant='h6'>
                    {productSideNav?.productSideNavContent?.product_name}
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                        onClose()
                    }}
                    aria-label="close"
                >
                    <SvgIcon fontSize='small'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </Box>
            <Table
                sx={{
                    '& th, & td': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell>Vendor Name:</TableCell>
                        <TableCell>{productSideNav?.productSideNavContent?.product_vendor_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Quantity:</TableCell>
                        <TableCell>{productSideNav?.productSideNavContent?.product_quantity}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Selling Price:</TableCell>
                        <TableCell>{productSideNav?.productSideNavContent?.product_amount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Inbound Shipping Cost:</TableCell>
                        <TableCell>{productSideNav?.productSideNavContent?.product_shipping_cost_in_dar}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Outbound Shipping Cost:</TableCell>
                        <TableCell>{productSideNav?.productSideNavContent?.product_shipping_cost_in_other_regions}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <ImageList sx={{ width: "100%", height: "200px", px: 2 }} cols={1} gap={0}>
                {productSideNav?.productSideNavContent?.product_files?.map((item, index) => (
                    <ImageListItem key={index}>
                        {item.file_type === "VIDEO" ?
                            <video
                                width="100%"
                                controls
                                src={`${item.video_url}`}
                            /> :
                            item.file_type === "YOUTUBE" ?
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={`${item.video_url}`}
                                    title={productSideNav?.productSideNavContent?.product_name}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                    style={{
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                    }}
                                /> :
                                <img
                                    src={`${item.image_url}?w=161&fit=crop&auto=format`}
                                    alt={productSideNav?.productSideNavContent?.product_name}
                                    loading="lazy"
                                />
                        }
                    </ImageListItem>
                ))}
            </ImageList>
        </Drawer>
    );

};

ViewProductSideNav.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};