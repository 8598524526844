import React from 'react';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, SvgIcon, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/date-formatter';
import { getOrderNumberFromdate } from '../../utils/constant';

export const ViewOrderStatusTrackingSideNav = (props) => {
    const { open, onClose } = props;
    const orderSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.100',
                    width: 300
                }
            }}
            sx={{ zIndex: 1300 }}
        >
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 2,
                        borderBottom: "2px solid grey",
                    }}
                >
                    <Typography variant='h6'>
                        Track Order No {orderSideNav?.orderStatusTrackingSideNavContent?.id + getOrderNumberFromdate(orderSideNav?.orderStatusTrackingSideNavContent?.created_at)}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            onClose()
                        }}
                        aria-label="close"
                    >
                        <SvgIcon fontSize='small'>
                            <XMarkIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>
                <List sx={{ width: '100%' }}>
                    {orderSideNav?.orderStatusTrackingSideNavContent?.order_status_tracking &&
                        orderSideNav.orderStatusTrackingSideNavContent.order_status_tracking.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={item.order_status === "NEEDTOCALLAGAIN" ? "NEED TO CALL AGAIN" : item.order_status}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {item.full_name}
                                                    </Typography>
                                                    {` - ${item.short_description}`}<br />
                                                    {formatDate(item.created_at)}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            )
                        })}
                </List>
            </Box>
        </Drawer >
    );

};

ViewOrderStatusTrackingSideNav.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};