import { getAllProductsUrl, getAllUsersByRoleUrl } from "./url"

export const vendorOrderFormFields = [
    { name: "full_name", type: "text", label: "Full Name", notRequired: false },
    { name: "region", type: "select", label: "Region", items: [], notRequired: false },
    { name: "district", type: "select", label: "District", items: [], notRequired: false },
    { name: "ward", type: "select", label: "Ward", items: [], notRequired: true },
    { name: "street", type: "text", label: "Street", notRequired: true },
    { name: "phone_number", type: "text", label: "Phone Number", notRequired: false },
    { name: "delivery_instruction", type: "text", label: "Delivery Instruction", notRequired: true },
    {
        name: "cart",
        type: "search",
        label: "Product Name",
        searchLabel: "product_name",
        value: "product_name",
        searchUrl: getAllProductsUrl,
        searchBody: {
            "status": "ALL",
            "sort": "id desc",
            "limit": 10,
            "page": 1
        },
        notRequired: false
    },
    { name: "product_quantity", type: "number", label: "Product Quantity", notRequired: false },
    { name: "s_k_u", type: "text", label: "SKU", notRequired: true },
    { name: "product_amount", type: "number", label: "Product Amount", notRequired: false },
    { name: "shipping_cost", type: "number", label: "Shipping Cost", notRequired: false },
    { name: "return_amount", type: "number", label: "Return Amount", notRequired: false },
]

export const orderFormFields = [
    { name: "full_name", type: "text", label: "Full Name", notRequired: false },
    { name: "region", type: "select", label: "Region", items: [], notRequired: false },
    { name: "district", type: "select", label: "District", items: [], notRequired: false },
    { name: "ward", type: "select", label: "Ward", items: [], notRequired: true },
    { name: "street", type: "text", label: "Street", notRequired: true },
    { name: "phone_number", type: "text", label: "Phone Number", notRequired: false },
    { name: "delivery_instruction", type: "text", label: "Delivery Instruction", notRequired: true },
    { name: "cart", type: "text", label: "Product Name", notRequired: false },
    { name: "product_quantity", type: "number", label: "Product Quantity", notRequired: false },
    { name: "s_k_u", type: "text", label: "SKU", notRequired: true },
    { name: "product_amount", type: "number", label: "Product Amount", notRequired: false },
    { name: "shipping_cost", type: "number", label: "Shipping Cost", notRequired: false },
    { name: "return_amount", type: "number", label: "Return Amount", notRequired: false },
    {
        name: "product_vendor_id",
        type: "search",
        label: "Vendor Name",
        searchLabel: "userName",
        value: "id",
        searchImage: "profileImage",
        searchUrl: getAllUsersByRoleUrl,
        searchBody: {
            role: "smai_vendor",
            sort: "id desc",
            limit: 10,
            page: 1
        },
        notRequired: false
    },
]

export const userFormFields = [
    { name: "first_name", type: "text", label: "First Name", notRequired: false },
    { name: "second_name", type: "text", label: "Second Name", notRequired: false },
    { name: "username", type: "text", label: "Userame", notRequired: false },
    { name: "email", type: "email", label: "Email", notRequired: false },
    { name: "phone_no", type: "text", label: "Phone Number (2557xxxxxxxx)", notRequired: false },
    {
        name: "gender",
        type: "select",
        label: "Gender",
        items: [
            { value: 'MALE', label: "MALE", },
            { value: 'FEMALE', label: "FEMALE", },
        ],
        notRequired: false
    },
    { name: "date_of_birth", type: "date", label: "Date Of Birth", notRequired: false },
]

export const vendorFormFields = [
    { name: "username", type: "text", label: "Vendor Name", notRequired: false },
    { name: "email", type: "email", label: "Email", notRequired: false },
    { name: "phone_no", type: "text", label: "Phone Number (2557xxxxxxxx)", notRequired: false },
    { name: "inbound_recharge_amount", type: "number", label: "Inbound Recharge Amount", notRequired: false },
    { name: "outbound_recharge_amount", type: "number", label: "Outbound Recharge Amount", notRequired: false },
    { name: "inbound_return_amount", type: "number", label: "Inbound Return Amount", notRequired: false },
    { name: "outbound_return_amount", type: "number", label: "Outbound Return Amount", notRequired: false },
    {
        name: "account_type",
        type: "select",
        label: "Account Type",
        items: [
            { value: 'MPESA', label: "MPESA", },
            { value: 'AIRTEL MONEY', label: "AIRTEL MONEY", },
            { value: 'TIGO PESA', label: "TIGO PESA", },
            { value: 'HALO PESA', label: "HALO PESA", },
            { value: 'TPESA', label: "TPESA", },
            { value: 'NMB', label: "NMB", },
            { value: 'CRDB', label: "CRDB", },
            { value: 'NBC', label: "NBC", },
            { value: 'OTHER BANKS', label: "OTHER BANKS", },
        ],
        notRequired: false
    },
    { name: "account_number", type: "text", label: "Account Number", notRequired: false },
    {
        name: "include_shipping_cost",
        type: "select",
        label: "Include Shipping Cost",
        items: [
            { value: 'NO', label: "NO", },
            { value: 'YES', label: "YES", },
        ],
        notRequired: false
    },
]

export const paymentsFormFields = [
    { name: "amount", type: "number", label: "Amount", notRequired: false },
    { name: "short_description", type: "text", label: "Short Description", notRequired: true },
    { name: "image", type: "file", label: "Attachment", notRequired: false },
]

export const vendorProductsFormFields = [
    { name: "product_name", type: "text", label: "Product Name", notRequired: false },
    { name: "product_quantity", type: "number", label: "Quantity", notRequired: false },
    { name: "product_amount", type: "number", label: "Selling Price", notRequired: false },
    { name: "product_shipping_cost_in_dar", type: "number", label: "Shipping Cost In Dar", notRequired: false },
    { name: "product_shipping_cost_in_other_regions", type: "number", label: "Shipping Cost In Other Regions", notRequired: false },
    {
        name: "multiply_shipping_cost",
        type: "select",
        label: "Multiply Shipping Cost",
        items: [
            { value: 'NO', label: "NO", },
            { value: 'YES', label: "YES", },
        ],
        notRequired: false
    },
]

export const productsFormFields = [
    ...vendorProductsFormFields,
    {
        name: "product_vendor_id",
        type: "search",
        label: "Vendor Name",
        searchLabel: "userName",
        value: "id",
        searchImage: "profileImage",
        searchUrl: getAllUsersByRoleUrl,
        searchBody: {
            role: "smai_vendor",
            sort: "id desc",
            limit: 10,
            page: 1
        },
        notRequired: false
    },
]

export const productYoutubeVideoFields = [
    { name: "image_path", type: "text", label: "Product Media URL" },
    { name: "thumbunail_url", type: "text", label: "Thumbnail URL" },
    {
        name: "file_type",
        type: "select",
        label: "Media Type",
        items: [
            { value: 'IMAGE', label: "IMAGE", },
            { value: 'VIDEO', label: "VIDEO", },
        ],
        notRequired: false
    },
]

export const expensesFormFields = [
    { name: "title", type: "text", label: "Title" },
    { name: "description", type: "text", label: "Description" },
    { name: "amount", type: "number", label: "Amount" },
    {
        name: "product_vendor_id",
        type: "search",
        label: "Vendor Name",
        searchLabel: "userName",
        value: "id",
        searchImage: "profileImage",
        searchUrl: getAllUsersByRoleUrl,
        searchBody: {
            role: "smai_vendor",
            sort: "id desc",
            limit: 10,
            page: 1
        },
        notRequired: false
    },
]