import { combineReducers } from "redux";
import SettingsReducer from "./settings-reducers";
import ViewPaymentSideNavReducer from "./view-payment-side-nav-reducers";
import FormInformationReducer from "./form-dialog";

const reducers = combineReducers({
    SettingsReducer,
    ViewPaymentSideNavReducer,
    FormInformationReducer,
});

export default reducers;