import { Box, MenuItem, Popover, TextField } from '@mui/material'
import React from 'react'
import { filterOrderStatus, filterOrderPaymentStatus } from '../../utils/constant';

function FilterOrderPopper(props) {
    const { anchorEl, onClose, open, id, filterItemsData, setFilterItemsData } = props;

    return (
        <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            PaperProps={{
                sx: {
                    width: 400
                }
            }}
        >
            <Box sx={{ p: 3 }}>
                <TextField
                    multiline
                    type="text"
                    label="Location"
                    margin="normal"
                    fullWidth
                    value={filterItemsData.location}
                    onChange={(event) => {
                        setFilterItemsData({
                            ...filterItemsData,
                            location: event.target.value
                        })
                    }}
                />
                <TextField
                    select
                    margin='normal'
                    label="Order Status"
                    onChange={(event) => {
                        setFilterItemsData({
                            ...filterItemsData,
                            order_status: event.target.value
                        })
                    }}
                    fullWidth
                    value={filterItemsData.order_status}
                >
                    {filterOrderPaymentStatus.map((status) => {

                        return (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                            >
                                {status.label}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    select
                    margin='normal'
                    label="Payment Status"
                    onChange={(event) => {
                        setFilterItemsData({
                            ...filterItemsData,
                            payment_status: event.target.value
                        })
                    }}
                    fullWidth
                    value={filterItemsData.payment_status}
                >
                    {filterOrderStatus.map((status) => {

                        return (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                            >
                                {status.label}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </Box>
        </Popover>
    )
}

export default FilterOrderPopper