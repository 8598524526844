import React from 'react'
import { Avatar, Box, Button, Container, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useAuth } from '../../hooks/use-auth'
import { usersUrl } from '../../seed/url'
import InlineForm from '../../components/InlineForm'

function Account() {
    const auth = useAuth()
    const [editName, setEditName] = React.useState(false);
    const [editEmail, setEditEmail] = React.useState(false);
    const [editPhone, setEditPhone] = React.useState(false);
    const [editAccountType, setEditAccountType] = React.useState(false);
    const [editAccountNumber, setEditAccountNumber] = React.useState(false);

    const handleChangeEditName = () => {
        setEditName(!editName);
    }

    const handleChangeEditEmail = () => {
        setEditEmail(!editEmail);
    }

    const handleChangeEditPhone = () => {
        setEditPhone(!editPhone);
    }

    const handleChangeEditAccountType = () => {
        setEditAccountType(!editAccountType);
    }

    const handleChangeEditAccountNumber = () => {
        setEditAccountNumber(!editAccountNumber);
    }

    return (
        <>
            <Container>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        my: 3
                    }}
                >
                    <Avatar
                        alt="profile user"
                        src={`${usersUrl}${auth?.user?.profile}`}
                        sx={{
                            width: 80,
                            height: 80
                        }}
                    />
                    <Typography
                        variant='h1'
                        sx={{ ml: 3 }}
                    >
                        {auth?.user?.name}
                    </Typography>
                </Box>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {!editName &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='h5'>
                                            Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body1'>
                                            {auth?.user?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='outlined' onClick={handleChangeEditName}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                            {editName &&
                                <TableRow>
                                    <InlineForm
                                        value={auth?.user?.name}
                                        type={"text"}
                                        label={"Name"}
                                        onSave={() => { }}
                                        onCancel={handleChangeEditName}
                                    />
                                </TableRow>
                            }
                            {!editEmail &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='h5'>
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body1'>
                                            {auth.user.email}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='outlined' onClick={handleChangeEditEmail}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                            {editEmail &&
                                <TableRow>
                                    <InlineForm
                                        value={auth.user.email}
                                        type={"email"}
                                        label={"Email"}
                                        onSave={() => { }}
                                        onCancel={handleChangeEditEmail}
                                    />
                                </TableRow>
                            }
                            {!editPhone &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='h5'>
                                            Phone Number
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body1'>
                                            {auth.user.phone}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='outlined' onClick={handleChangeEditPhone}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                            {editPhone &&
                                <TableRow>
                                    <InlineForm
                                        value={auth.user.phone}
                                        type={"text"}
                                        label={"Phone"}
                                        onSave={() => { }}
                                        onCancel={handleChangeEditPhone}
                                    />
                                </TableRow>
                            }
                            {!editAccountType &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='h5'>
                                            Payment Channel
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body1'>
                                            MPesa
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='outlined' onClick={handleChangeEditAccountType}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                            {editAccountType &&
                                <TableRow>
                                    <InlineForm
                                        value={auth.user.account_type}
                                        type={"text"}
                                        label={"Phone"}
                                        onSave={() => { }}
                                        onCancel={handleChangeEditAccountType}
                                    />
                                </TableRow>
                            }
                            {!editAccountNumber &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='h5'>
                                            Payment Number
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body1'>
                                            {auth.user.phone}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant='outlined' onClick={handleChangeEditAccountNumber}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                            {editAccountNumber &&
                                <TableRow>
                                    <InlineForm
                                        value={auth.user.account_number}
                                        type={"text"}
                                        label={"Phone"}
                                        onSave={() => { }}
                                        onCancel={handleChangeEditAccountNumber}
                                    />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default Account