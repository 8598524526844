// assets
import { ProductOutlined } from '@ant-design/icons';

// icons
const icons = {
  ProductOutlined,
};

// ==============================|| MENU ITEMS - INVENTORY ||============================== //

const inventory = {
  id: 'authentication',
  title: 'Inventory',
  type: 'group',
  children: [
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/vendor/inventory',
      icon: icons.ProductOutlined
    }
  ]
};

export default inventory;
