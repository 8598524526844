import * as React from "react"
// material-ui
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography
} from '@mui/material';

// project import
import MainCard from '../../components/MainCard';
import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';

// assets
import TransactionHistory from '../../components/TransactionHistory';
import TopRegionsBarChart from './TopRegionsBarChart';
import { OrdersPieChart } from './OrdersPieChart';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { usePopover } from "../../hooks/use-popover";
import { CustomPopOver } from "../../components/custom-popover";
import { postRequest } from "../../services/api-service";
import { getProductsOrdersStatisticsForVendorUrl, getProductsOrdersTopRegionsForVendorUrl } from "../../seed/url";
import { formatMoney, getPercenteage } from "../../utils/constant";
import { useAuth } from "../../hooks/use-auth";
import VendorLayout from "../../layouts/VendorLayout";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const VendorHome = () => {
  const auth = useAuth();
  const popOver = usePopover();
  const [isLoading, setIsLoading] = React.useState(true)
  const [isLoadingTopRegions, setIsLoadingTopRegions] = React.useState(true)
  const [topRegions, setTopRegions] = React.useState([])
  const transactionHistory = [];
  const [dashboardData, setDashboardData] = React.useState({
    total_orders: 0,
    confirmed_orders: 0,
    in_transit_orders: 0,
    delivered_orders: 0,
    returned_orders: 0,
    cancelled_orders: 0,
    total_orders_amount: 0,
    delivered_orders_amount: 0,
    returned_orders_amount: 0,
    cancelled_orders_amount: 0
  });
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day'),
    "to": dayjs(),
  });

  const handleBodyChange = (newValue, key) => {
    setBody({ ...body, [key]: newValue, })
  }

  const getProductsOrdersStatistics = React.useCallback(
    () => {
      setIsLoading(true)
      postRequest(
        getProductsOrdersStatisticsForVendorUrl,
        {
          from: body.from.format('YYYY-MM-DD HH:mm:ss.SSS'),
          to: body.to.format('YYYY-MM-DD HH:mm:ss.SSS'),
          user_id: auth?.user?.id
        },
        (data) => {
          setDashboardData(data);
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
        },
      )
    }, [body, auth])

  const getProductsOrdersTopRegions = React.useCallback(
    () => {
      postRequest(
        getProductsOrdersTopRegionsForVendorUrl,
        {
          user_id: auth?.user?.id,
        },
        (data) => {
          setTopRegions(data);
          setIsLoadingTopRegions(false);
        },
        (error) => {
          setIsLoadingTopRegions(false);
        },
      )
    }, [auth])

  React.useEffect(() => {
    getProductsOrdersStatistics()
  }, [getProductsOrdersStatistics]);

  React.useEffect(() => {
    getProductsOrdersTopRegions()
  }, [getProductsOrdersTopRegions]);

  return (
    <VendorLayout>
      <CustomPopOver
        id={popOver.id}
        anchorEl={popOver.anchorRef}
        open={popOver.open}
        onClose={popOver.handleClose}
        showDates={true}
        from={body.from}
        to={body.to}
        handleBodyChange={handleBodyChange}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          mb: 3,
        }}
      >
        <Typography sx={{ mr: "auto" }} variant="h5">Dashboard</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: 1,
            borderColor: '#bfbfbf'
          }}
        >
          <Button
            sx={{
              color: "grey"
            }}
            variant='text'
            startIcon={
              <CalendarOutlined />
            }
            endIcon={
              <DownOutlined />
            }
            onClick={(event) => {
              popOver.handleOpen(event)
            }}
          >
            {`${body.from.format('MMMM D, YYYY HH:mm:ss')} - `}
            {`${body.to.format('MMMM D, YYYY HH:mm:ss')}`}
          </Button>
        </Box>
      </Box>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {/* row 1 */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce
            title="Total Orders"
            count={dashboardData.total_orders}
            amount={[formatMoney(dashboardData.total_orders_amount)]}
            percentage={100}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce
            title="Delivered"
            count={dashboardData.delivered_orders}
            amount={[formatMoney(dashboardData.delivered_orders_amount)]}
            percentage={getPercenteage(dashboardData.delivered_orders, dashboardData.total_orders)}
            color="success"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce
            title="Returned"
            count={dashboardData.returned_orders}
            amount={[formatMoney(dashboardData.returned_orders_amount)]}
            percentage={getPercenteage(dashboardData.returned_orders, dashboardData.total_orders)}
            color="warning"
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce
            title="Cancelled"
            count={dashboardData.cancelled_orders}
            amount={[formatMoney(dashboardData.cancelled_orders_amount)]}
            percentage={getPercenteage(dashboardData.cancelled_orders, dashboardData.total_orders)}
            color="error"
            isLoading={isLoading}
          />
        </Grid>

        <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

        {topRegions.length > 0 &&
          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Top Regions</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              <TopRegionsBarChart
                topRegions={topRegions}
                isLoadingTopRegions={isLoadingTopRegions}
              />
            </MainCard>
          </Grid>
        }

        {(dashboardData.delivered_orders > 0 ||
          dashboardData.returned_orders > 0 ||
          dashboardData.cancelled_orders > 0) &&
          <Grid item xs={12} md={5} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Orders Summary</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2, pt: 5 }} content={false}>
              <OrdersPieChart
                chartSeries={[
                  dashboardData.delivered_orders,
                  dashboardData.returned_orders,
                  dashboardData.cancelled_orders
                ]}
                labels={['Delivered', 'Returned', 'Cancelled']}
                deliveryRates={[
                  {
                    value: getPercenteage(dashboardData.delivered_orders, dashboardData.total_orders),
                    label: "Delivery Rate"
                  },
                  {
                    value: getPercenteage(dashboardData.returned_orders, dashboardData.delivered_orders),
                    label: "Return Rate"
                  },
                  {
                    value: getPercenteage(dashboardData.confirmed_orders, dashboardData.total_orders),
                    label: "Confirmation Rate"
                  }
                ]}
                isLoading={isLoading}
              />
            </MainCard>
          </Grid>
        }

        <Grid item xs={12} md={5} lg={4}>
          {transactionHistory.length > 0 &&
            <>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">Transaction History</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <TransactionHistory data={transactionHistory} />
            </>
          }
          <MainCard sx={{ mt: transactionHistory.length === 0 ? 0 : 2 }}>
            <Stack spacing={2}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Stack>
                    <Typography variant="h5" noWrap>
                      Help & Support Chat
                    </Typography>
                    <Typography variant="caption" color="secondary" noWrap>
                      Typical replay within 2 min
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <a
                href={`https://wa.me/255677080939?text=Hello SMAI Express`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  fullWidth
                >
                  Need Help?
                </Button>
              </a>
            </Stack>
          </MainCard>
        </Grid>

      </Grid>
    </VendorLayout>
  );
};

export default VendorHome;
