// project import
import inventory from './inventory';
import dashboard from './dashboard';
import orders from './orders';
import account from './account';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, orders, inventory, account]
};

export default menuItems;
