import React from 'react'
import { useAuth } from '../../hooks/use-auth'
import Home from '../Home/Home';
import VendorHome from '../VendorHome';

function Dashboard() {
    const auth = useAuth();

    return (
        <>
            {auth.user?.role !== "smai_vendor" ? <Home /> : <VendorHome />}
        </>
    )
}

export default Dashboard