import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';
import { Button, Card, IconButton, InputAdornment, OutlinedInput, SvgIcon, Tooltip } from '@mui/material';
import ArrowDownTrayIcon from '@heroicons/react/24/outline/ArrowDownTrayIcon';
import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import { CustomPopOver } from './custom-popover';
import { usePopover } from '../hooks/use-popover';

export const CustomSearch = ({
  popoverItems,
  handleSearch,
  exportExcel,
  getDataForExportExcel,
  selectedItems,
  confirmSelectedOrderStatus,
  statusOfOrder,
}) => {
  const popOver = usePopover();

  return (
    <Card
      elevation={1}
      sx={{
        p: 2,
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <CustomPopOver
        id={popOver.id}
        anchorEl={popOver.anchorRef}
        open={popOver.open}
        onClose={popOver.handleClose}
        popoverItems={popoverItems}
      />
      <OutlinedInput
        defaultValue=""
        fullWidth
        placeholder="Search"
        startAdornment={(
          <InputAdornment position="start">
            <SvgIcon
              color="action"
              fontSize="small"
            >
              <MagnifyingGlassIcon />
            </SvgIcon>
          </InputAdornment>
        )}
        sx={{ maxWidth: 500, mr: "auto" }}
        onChange={(event) => handleSearch && handleSearch(event)}
      />
      {confirmSelectedOrderStatus && statusOfOrder.map((item, index) => {
        return (
          <Button
            key={index}
            disabled={selectedItems.selected.length === 0}
            onClick={(event) => {
              confirmSelectedOrderStatus(item.value)
            }}
            variant="contained"
            sx={{
              color: "neutral.100",
              m: 1
            }}
          >
            {item.label}
          </Button>
        );
      })
      }
      {popoverItems &&
        <>
          <Tooltip title={'Download excel'}>
            <IconButton
              disabled={exportExcel}
              onClick={() => {
                getDataForExportExcel && getDataForExportExcel()
              }}
            >
              <SvgIcon fontSize="medium" sx={{ color: "text.primary", mx: 0.5 }}>
                <ArrowDownTrayIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={'Filter'}>
            <IconButton
              onClick={(event) => {
                popOver.handleOpen(event)
              }}
            >
              <SvgIcon fontSize="medium" sx={{ color: "text.secondary" }}>
                <FunnelIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </>
      }
    </Card>
  );
}
