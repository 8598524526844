import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from '../../../components/MainCard';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticEcommerce = ({ color, title, count, amount, percentage, isLoading }) => (
  <MainCard contentSX={{ p: 2.25 }}>
    <Stack spacing={0.5}>
      <Typography variant="h6" color="textSecondary">
        {title}
      </Typography>
      {isLoading &&
        <CircularProgress
          size={30}
          sx={{
            mx: 'auto',
            mt: 2,
          }}
        />
      }
      {!isLoading &&
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4" color="inherit">
              {count} [{amount}]
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              variant="combined"
              color={color}
              label={`${percentage || 0}%`}
              sx={{ ml: 1.25, pl: 1 }}
              size="small"
            />
          </Grid>
        </Grid>
      }
    </Stack>
    <Box sx={{ pt: 2.25 }}>
      <Typography variant="caption" color="textSecondary">
        All time records
      </Typography>
    </Box>
  </MainCard>
);

AnalyticEcommerce.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  percentage: PropTypes.number,
};

AnalyticEcommerce.defaultProps = {
  color: 'primary'
};

export default AnalyticEcommerce;
