import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import { Box, CircularProgress } from '@mui/material';

// ==============================|| MONTHLY BAR CHART ||============================== //

const MonthlyBarChart = ({ topRegions, isLoadingTopRegions }) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const success = theme.palette.success.light;

  const [series, setSeries] = useState([
    {
      name: 'Orders',
      data: []
    }
  ]);

  useEffect(() => {
    setSeries([
      {
        name: 'Orders',
        data: topRegions.map(value => value.total_orders)
      }
    ]);
  }, [topRegions])

  // chart options
  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 365,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '75%',
        borderRadius: 4
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false
    }
  };

  const [options, setOptions] = useState(barChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [success],
      xaxis: {
        categories: topRegions.map(label => label.region),
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary]
          }
        }
      },
      tooltip: {
        theme: 'light'
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary, success, secondary, topRegions]);

  return (
    <>
      {isLoadingTopRegions &&
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          height: 365
        }}>
          <CircularProgress
            sx={{
              mx: 'auto',
              my: 3,
            }}
          />
        </Box>
      }
      {!isLoadingTopRegions &&
        <div id="chart">
          <ReactApexChart options={options} series={series} type="bar" height={365} />
        </div>
      }
    </>
  );
};

export default MonthlyBarChart;
