import React from 'react'
import { Box, Button, TextField } from '@mui/material'

function InlineForm({ value, type, label, onSave, onCancel }) {
    const [data, setData] = React.useState(value);
    const [error,] = React.useState("");

    return (
        <>
            <TextField
                id={"inline-form"}
                multiline
                required
                name={"inline-form"}
                type={type}
                label={label}
                margin="normal"
                fullWidth
                value={data}
                error={error}
                onChange={(event) => setData(event.target.value)}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}
            >
                <Button variant='contained' sx={{ mr: 2 }} onClick={onSave}>Save</Button>
                <Button variant='outlined' onClick={onCancel}>Cancel</Button>
            </Box>
        </>
    )
}

export default InlineForm