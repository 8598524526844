import React from "react";
import {
    Box,
    Button,
    Container,
    Stack,
    SvgIcon,
    Typography,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { externalVendorOrdersHeadCells } from "../../seed/table-headers";
import {
    CREATE,
    UPDATE,
    allTimeTimeRange,
    getDistrictId,
    getRegionId,
    getWardId,
    lastSevenDaysTimeRange,
    thisMonthTimeRange,
    todayTimeRange,
    yesterdayTimeRange,
} from "../../utils/constant";
import { getRequest, postRequest } from "../../services/api-service";
import {
    createExternalOrderUrl,
    deleteExternalOrderUrl,
    getAllDistrictsUrl,
    getAllExternalOrdersByPaginationUrl,
    getAllRegionsUrl,
    getAllWardsUrl,
    updateExternalOrderUrl,
} from "../../seed/url";
import { useDispatch, useSelector } from "react-redux";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import { FormDialog } from "../../components/form-dialog";
import { vendorOrderFormFields } from "../../seed/form-fields";
import { usePopover } from "../../hooks/use-popover";
import FilterOrderPopper from "../Orders/FilterOrderPopper";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { CustomAlert } from "../../components/custom-alert";
import dayjs from "dayjs";
import { handleExport } from "../Orders/Orders";
import { useAuth } from "../../hooks/use-auth";
import VendorViewOrder from "../Orders/VendorViewOrder";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";

const useProductsOrdersIds = (externalOrders) => {
    return React.useMemo(() => {
        return externalOrders.map((customer) => customer.id);
    }, [externalOrders]);
};

function Billing() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const orderSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);
    const formInfo = useSelector((state) => state.FormInformationReducer);
    const popOver = usePopover();
    const [action, setAction] = React.useState(CREATE);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [regions, setRegions] = React.useState([]);
    const [districts, setDistricts] = React.useState([]);
    const [wards, setWards] = React.useState([]);
    const [formFields, setFormFields] = React.useState(vendorOrderFormFields);
    const [productsOrders, setProductsOrders] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: [],
    });
    const [selectedData, setSelectedData] = React.useState({});
    const [filterItemsData, setFilterItemsData] = React.useState({
        location: "",
        order_status: "",
        payment_status: "",
    });
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [exportExcel, setExportExcel] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const productsIds = useProductsOrdersIds(productsOrders.results);
    const productsOrdersSelection = useSelection(productsIds);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openViewDialog, setOpenViewDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [severity, setSeverity] = React.useState("");
    const [severityMessage, setSeverityMessage] = React.useState("");
    const values = [
        {
            id: action === UPDATE ? selectedData.id : 0,
            order_id: action === UPDATE ? selectedData.order_id : "",
            full_name: action === UPDATE ? selectedData.full_name : "",
            region: action === UPDATE ? getRegionId(regions, selectedData.region) : "",
            district: action === UPDATE ? getDistrictId(districts, selectedData.district) : "",
            ward: action === UPDATE ? getWardId(wards, selectedData.ward) : "",
            street: action === UPDATE ? selectedData.street : "",
            phone_number: action === UPDATE ? selectedData.phone_number : "",
            delivery_instruction:
                action === UPDATE ? selectedData.delivery_instruction : "",
            cart: action === UPDATE ? selectedData.cart : "",
            product_quantity:
                action === UPDATE ? selectedData?.product_quantity || 0 : 0,
            s_k_u: action === UPDATE ? selectedData.s_k_u : "",
            product_amount: action === UPDATE ? selectedData.product_amount : 0,
            shipping_cost: action === UPDATE ? selectedData.shipping_cost : 0,
            return_amount: action === UPDATE ? selectedData.return_amount : auth?.user?.outbound_return_amount,
            product_vendor_id: action === UPDATE ? selectedData.product_vendor_id : auth?.user?.id,
        },
    ];
    const [timeRange, setTimeRange] = React.useState({
        from: dayjs("1970-01-01T00:00:00Z").format("YYYY-MM-DD HH:mm:ss.SSS"),
        to: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS"),
    });
    const filterItems = [
        {
            label: "Today",
            onClick: () => {
                setTimeRange(todayTimeRange);
            },
        },
        {
            label: "Yesterday",
            onClick: () => {
                setTimeRange(yesterdayTimeRange);
            },
        },
        {
            label: "Last 7 days",
            onClick: () => {
                setTimeRange(lastSevenDaysTimeRange);
            },
        },
        {
            label: "This month",
            onClick: () => {
                setTimeRange(thisMonthTimeRange);
            },
        },
        {
            label: "All time",
            onClick: () => {
                setTimeRange(allTimeTimeRange);
            },
        },
    ];
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getDataForExportExcel = () => {
        setExportExcel(true);
        postRequest(
            getAllExternalOrdersByPaginationUrl,
            {
                query: `${searchTerm}${filterItemsData.location === "" || searchTerm === ""
                    ? ""
                    : " " + filterItemsData.location
                    }${filterItemsData.order_status === ""
                        ? ""
                        : " " + filterItemsData.order_status
                    }${filterItemsData.payment_status === ""
                        ? ""
                        : " " + filterItemsData.payment_status
                    }`,
                status: "DELIVERED",
                vendor_id: auth?.user?.id,
                from: timeRange.from,
                to: timeRange.to,
                sort: orderBy + " " + order,
                limit: productsOrders.total_results,
                page: 1,
            },
            (data) => {
                handleExport(data?.results);
                setExportExcel(false);
            },
            (error) => {
                setExportExcel(false);
            }
        );
    };

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true);
            postRequest(
                getAllExternalOrdersByPaginationUrl,
                {
                    query: `${searchTerm}${filterItemsData.location === "" || searchTerm === ""
                        ? ""
                        : " " + filterItemsData.location
                        }${filterItemsData.order_status === ""
                            ? ""
                            : " " + filterItemsData.order_status
                        }${filterItemsData.payment_status === ""
                            ? ""
                            : " " + filterItemsData.payment_status
                        }`,
                    status: "DELIVERED",
                    vendor_id: auth?.user?.id,
                    from: timeRange.from,
                    to: timeRange.to,
                    sort: orderBy + " " + order,
                    limit: rowsPerPage,
                    page: page,
                },
                (data) => {
                    setProductsOrders(data);
                    setIsLoading(false);
                },
                (error) => {
                    setProductsOrders({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    });
                    setIsLoading(false);
                }
            );
        },
        [rowsPerPage, searchTerm, filterItemsData, timeRange, orderBy, order, auth]
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const getAllDistricts = React.useCallback((regionId) => {
        setIsLoading(true)
        postRequest(
            getAllDistrictsUrl,
            {
                "id": regionId
            },
            (data) => {
                const newDistricts = data.map((category) => {
                    const newItem = {};
                    ["label", "value"].forEach((item) => {
                        if (item === "label") {
                            newItem[item] = category.district_name;
                        }
                        if (item === "value") {
                            newItem[item] = category.id;
                        }
                    });
                    return newItem;
                });
                let newFormFields = formFields;
                newFormFields[3].items = newDistricts;
                setFormFields(newFormFields);
                setDistricts(data)
                setIsLoading(false)
            },
            (error) => {
                let newFormFields = formFields;
                newFormFields[3].items = [];
                setFormFields(newFormFields);
                setIsLoading(false)
            },
        )
    }, [formFields])

    const getAllWards = React.useCallback((districtId) => {
        setIsLoading(true)
        postRequest(
            getAllWardsUrl,
            {
                "id": districtId
            },
            (data) => {
                const newDistricts = data.map((category) => {
                    const newItem = {};
                    ["label", "value"].forEach((item) => {
                        if (item === "label") {
                            newItem[item] = category.ward_name;
                        }
                        if (item === "value") {
                            newItem[item] = category.id;
                        }
                    });
                    return newItem;
                });
                let newFormFields = formFields;
                newFormFields[4].items = newDistricts;
                setFormFields(newFormFields);
                setWards(data)
                setIsLoading(false)
            },
            (error) => {
                let newFormFields = formFields;
                newFormFields[4].items = [];
                setFormFields(newFormFields);
                setIsLoading(false)
            },
        )
    }, [formFields])

    React.useEffect(() => {
        fetcher(1);
    }, [fetcher]);

    React.useEffect(() => {
        getRequest(
            getAllRegionsUrl,
            (data) => {
                setRegions(data);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }, [])

    React.useEffect(() => {
        if (formInfo.region) {
            getAllDistricts(formInfo?.region)
        }
    }, [formInfo, getAllDistricts])

    React.useEffect(() => {
        if (formInfo.district) {
            getAllWards(formInfo?.district)
        }
    }, [formInfo, getAllWards])

    React.useEffect(() => {
        getAllWards(getDistrictId(districts, selectedData.district))
    }, [getAllWards, districts, selectedData])

    React.useEffect(() => {
        if (regions.length > 0) {
            const newRegions = regions.map((region) => {
                const newItem = {};
                ["label", "value", "id"].forEach((item) => {
                    if (item === "label") {
                        newItem[item] = region.region_name;
                    }
                    if (item === "value") {
                        newItem[item] = region.id;
                    }
                });
                return newItem;
            });
            let newFormFields = formFields;
            newFormFields[2].items = newRegions;
            setFormFields(newFormFields);
        }
    }, [regions, formFields])

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1);
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    const handleClickOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        action === UPDATE ? fetcher(productsOrders.page) : fetcher(1);
        setOpenCreateDialog(false);
        setAction(CREATE);
    };

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenAlert(false);
    };

    const handleClickOpenViewDialog = () => {
        setOpenViewDialog(true)
    }

    const handleCloseViewDialog = () => {
        fetcher(productsOrders.page)
        setOpenViewDialog(false)
    }

    const onSelect = (data, openDialog) => {
        setSelectedData(data);
        getAllDistricts(getRegionId(regions, data.region))
        openDialog && handleClickOpenViewDialog()
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        postRequest(
            deleteExternalOrderUrl,
            {
                id: selectedData.id,
            },
            (data) => {
                fetcher(productsOrders.page);
                setSeverityMessage(data.message);
                setSeverity("success");
                handleClickAlert();
                setIsDeleting(false);
                handleCloseDeleteDialog();
            },
            (error) => {
                if (error?.response?.data?.message) {
                    setSeverityMessage(error.response.data.message[0]);
                    setSeverity("error");
                    handleClickAlert();
                }
                setIsDeleting(false);
            }
        );
    };

    const orderPopoverItems = [
        {
            id: "track_order",
            label: "Track Order",
            icon: (
                <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
                    <EyeIcon />
                </SvgIcon>
            ),
            onClick: () => {
                dispatch({
                    type: "TOOGLE_PAYMENT_SIDENAV",
                    payload: {
                        ...orderSideNav,
                        openViewOrderStatusTrackingSideNav: true,
                        orderStatusTrackingSideNavContent: selectedData,
                    },
                });
            },
        },
        {
            id: "edit",
            label: "Edit Order",
            icon: (
                <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
                    <PencilIcon />
                </SvgIcon>
            ),
            onClick: () => {
                setAction(UPDATE);
                if (selectedData.order_status === "RECEIVED") {
                    handleClickOpenCreateDialog();
                } else {
                    setSeverityMessage("Order Cannot be Edited Because has Aleardy been Confirmed")
                    setSeverity("error")
                    handleClickAlert()
                }
            },
        },
        {
            id: "delete",
            label: "Delete Order",
            icon: (
                <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
                    <TrashIcon />
                </SvgIcon>
            ),
            onClick: () => {
                handleClickOpenDeleteDialog();
                setSeverityMessage("Not Allowed to Perform this Action")
                setSeverity("error")
                handleClickAlert()
            },
        },
    ];

    return (
        <>
            {openAlert && (
                <CustomAlert
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    severity={severity}
                    severityMessage={severityMessage}
                />
            )}
            {popOver.open && (
                <FilterOrderPopper
                    id={popOver.id}
                    anchorEl={popOver.anchorRef}
                    open={popOver.open}
                    onClose={popOver.handleClose}
                    filterItemsData={filterItemsData}
                    setFilterItemsData={setFilterItemsData}
                />
            )}
            {openCreateDialog && (
                <FormDialog
                    open={openCreateDialog}
                    handleClose={handleCloseCreateDialog}
                    dialogTitle={"Order"}
                    action={action}
                    fields={vendorOrderFormFields}
                    values={values}
                    url={
                        action === CREATE ? createExternalOrderUrl : updateExternalOrderUrl
                    }
                />
            )}
            {openDeleteDialog && (
                <ConfirmationDialog
                    open={openDeleteDialog}
                    handleClose={handleCloseDeleteDialog}
                    handleAction={handleDelete}
                    isPerformingAction={isDeleting}
                    dialogTitle={"Delete Alert"}
                    dialogContentText={"Are you sure you want to delete this item?"}
                />
            )}
            {openViewDialog &&
                <VendorViewOrder
                    open={openViewDialog}
                    handleClose={handleCloseViewDialog}
                    data={selectedData}
                    setData={setSelectedData}
                />
            }
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pt: 2,
                    pb: 8,
                }}
            >
                <Container maxWidth={false}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between" spacing={4}>
                            <Stack spacing={1}>
                                <Typography variant="h4">Orders</Typography>
                            </Stack>
                            <div>
                                <Button
                                    onClick={(event) => {
                                        popOver.handleOpen(event);
                                    }}
                                    endIcon={
                                        <SvgIcon fontSize="small">
                                            <ChevronDownIcon />
                                        </SvgIcon>
                                    }
                                    variant="contained"
                                    sx={{
                                        color: "neutral.100",
                                        m: 1,
                                    }}
                                >
                                    Filter By
                                </Button>
                            </div>
                        </Stack>
                        <CustomSearch
                            popoverItems={filterItems}
                            handleSearch={handleSearch}
                            exportExcel={exportExcel}
                            getDataForExportExcel={getDataForExportExcel}
                        />
                        <CustomTable
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            count={productsOrders.total_results}
                            items={productsOrders.results}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelectOne={productsOrdersSelection.handleSelectOne}
                            onSelect={onSelect}
                            page={
                                productsOrders.page >= 1
                                    ? productsOrders.page - 1
                                    : productsOrders.page
                            }
                            rowsPerPage={rowsPerPage}
                            selected={productsOrdersSelection.selected}
                            headCells={externalVendorOrdersHeadCells}
                            popoverItems={orderPopoverItems}
                            isLoading={isLoading}
                        />
                    </Stack>
                </Container>
            </Box>
        </>
    );
}

export default Billing;
