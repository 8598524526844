import PropTypes from 'prop-types';
import {
    Box,
    CircularProgress,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import { Chart } from '../../components/chart';
import { CheckCircleOutlined, TruckOutlined, XOutlined } from '@ant-design/icons';

const useChartOptions = (labels) => {
    const theme = useTheme();

    return {
        chart: {
            background: 'transparent'
        },
        colors: [
            theme.palette.success.main,
            theme.palette.warning.main,
            theme.palette.error.main
        ],
        dataLabels: {
            enabled: false
        },
        labels,
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                expandOnClick: false
            }
        },
        states: {
            active: {
                filter: {
                    type: 'none'
                }
            },
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        stroke: {
            width: 0
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            fillSeriesColor: false
        }
    };
};

const iconMap = [
    <TruckOutlined />,
    <CheckCircleOutlined />,
    <XOutlined />
];

export const OrdersPieChart = (props) => {
    const { chartSeries, labels, deliveryRates, isLoading } = props;
    const chartOptions = useChartOptions(labels);

    return (
        <>
            {isLoading &&
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 365
                }}>
                    <CircularProgress
                        sx={{
                            mx: 'auto',
                            my: 3,
                        }}
                    />
                </Box>
            }
            {!isLoading &&
                <>
                    <Chart
                        height={300}
                        options={chartOptions}
                        series={chartSeries}
                        type="donut"
                        width="100%"
                    />
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        sx={{ mt: 2 }}
                    >
                        {deliveryRates.map((item, index) => {

                            return (
                                <Box
                                    key={item.label}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    {iconMap[index]}
                                    <Typography
                                        sx={{ my: 1 }}
                                        variant="h6"
                                    >
                                        {item.label}
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        variant="subtitle2"
                                    >
                                        {item.value}%
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Stack>
                </>
            }
        </>
    );
};

OrdersPieChart.propTypes = {
    chartSeries: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    sx: PropTypes.object
};