// assets
import { BellOutlined, MoneyCollectOutlined, SettingOutlined, UnorderedListOutlined, WalletOutlined } from '@ant-design/icons';

// icons
const icons = {
  BellOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  WalletOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const account = {
  id: 'account',
  title: 'Account',
  type: 'group',
  children: [
    {
      id: 'wallet',
      title: 'Wallet',
      type: 'item',
      url: '/wallet',
      icon: icons.MoneyCollectOutlined
    },
    {
      id: 'billing',
      title: 'Billing',
      type: 'item',
      url: '/billing',
      icon: icons.WalletOutlined
    },
    {
      id: 'transaction-history',
      title: 'History',
      type: 'item',
      url: '/transaction-history',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'notifications',
      title: 'Notifications',
      type: 'item',
      url: '/notifications',
      icon: icons.BellOutlined
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined
    }
  ]
};

export default account;
