const initialState = {
    openViewPaymentSideNav: false,
    openViewOrderSideNav: false,
    openViewOrderStatusTrackingSideNav: false,
    openViewProductSideNav: false,
    orderSideNavContent: {},
    orderStatusTrackingSideNavContent: {},
    productSideNavContent: {}
}

export default function ViewPaymentSideNavReducer(viewPaymentSideNav = initialState, action) {

    switch (action.type) {

        case "TOOGLE_PAYMENT_SIDENAV":

            return action.payload;

        default:
            return viewPaymentSideNav;
    }

};