import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

export const CustomTabs = ({ currentTab, setCurrentTab, tabsData, style, indicatorColor }) => {
  const handleTabChange = React.useCallback(
    (event, value) => {
      setCurrentTab(value)
    },
    [setCurrentTab]
  )

  return (
    <Box sx={{ borderBottom: 1, borderColor: '#bfbfbf' }}>
      <Tabs
        onChange={handleTabChange}
        value={currentTab}
        variant='scrollable'
        scrollButtons="auto"
        indicatorColor={indicatorColor}
      >
        {tabsData.map((item, index) => {

          return (
            <Tab
              key={index}
              label={item.label}
              value={item.value}
              sx={{
                ...(currentTab === item.value && style)
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
