import React from 'react'
import { Avatar, Box, CircularProgress, Container, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { formatMoney, getOrderNumberFromdate } from '../../utils/constant';
import { formatDate, formatDateForExcel } from '../../utils/date-formatter';
import { postRequest } from '../../services/api-service';
import { getAllExternalOrdersByPaginationUrl } from '../../seed/url';
import dayjs from 'dayjs';

function DownloadVendorInvoice({ selected, expenses, productsOrders }) {
    const amountPaid = selected.total_products_cost - selected.total_returned_cost - selected.total_expenses;
    const [isLoading, setIsLoading] = React.useState(true);
    const [orders, setOrders] = React.useState(true);

    const getDataForExportExcel = React.useCallback(
        () => {
            setIsLoading(true);
            postRequest(
                getAllExternalOrdersByPaginationUrl,
                {
                    invoice_number: selected.invoice_number,
                    vendor_id: selected.product_vendor_id,
                    from: dayjs("1970-01-01T00:00:00Z").format("YYYY-MM-DD HH:mm:ss.SSS"),
                    to: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS"),
                    sort: "id desc",
                    limit: productsOrders.total_results,
                    page: 1,
                },
                (data) => {
                    setOrders(data?.results);
                    setIsLoading(false);
                },
                (error) => {
                    setIsLoading(false);
                }
            );
        },
        [productsOrders, selected]
    );

    React.useEffect(() => {
        getDataForExportExcel();
    }, [getDataForExportExcel]);

    return (
        <Box
            id="html-content"
            component="main"
            sx={{
                flexGrow: 1,
                p: 8,
            }}
        >
            {isLoading &&
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <CircularProgress
                        sx={{
                            mx: 'auto',
                            my: 3,
                        }}
                    />
                </Box>
            }
            {!isLoading &&
                <Container maxWidth={false}>
                    <Stack spacing={2}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Avatar
                                variant='rounded'
                                alt="Logo"
                                src={"/assets/images/logo.png"}
                                sx={{
                                    width: 100,
                                    height: 100
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    mx: "auto"
                                }}
                            >
                                <Typography
                                    variant='h4'
                                >
                                    SMAI Express
                                </Typography>
                                <Typography
                                    variant='h5'
                                >
                                    Vendor Invoice
                                </Typography>
                            </Box>
                            <Avatar
                                variant='rounded'
                                alt="Logo"
                                src={"/assets/images/logo.png"}
                                sx={{
                                    width: 100,
                                    height: 100
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <Box>
                                <Typography variant='h5'>
                                    Invoice No: #{selected.invoice_number}
                                </Typography>
                                <Typography fontSize={20}>
                                    Vendor Name: {selected.product_vendor_name}
                                </Typography>
                                <Typography fontSize={20}>
                                    Generated By: {selected.full_name}
                                </Typography>
                                <Typography fontSize={20}>
                                    Date Generated: {formatDateForExcel(selected.created_at)}
                                </Typography>
                            </Box>
                            <Table
                                sx={{
                                    width: 600,
                                    "& th, & td": {
                                        border: "0.5px solid grey",
                                        fontSize: 10,
                                        padding: 0.3,
                                    },
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="right">Count</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Product Cost
                                        </TableCell>
                                        <TableCell align="right">
                                            {selected.total_products_count}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatMoney(selected.total_products_cost || 0)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Shipping Cost
                                        </TableCell>
                                        <TableCell align="right">
                                            {selected.total_shipping_count}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatMoney(selected.total_shipping_cost || 0)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Returned Cost
                                        </TableCell>
                                        <TableCell align="right">
                                            {selected.total_returned_count}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatMoney(selected.total_returned_cost || 0)}
                                        </TableCell>
                                    </TableRow>
                                    {expenses.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {item.title}
                                                </TableCell>
                                                <TableCell align="right" colSpan={2}>
                                                    {formatMoney(item.amount || 0)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Amount Paid
                                        </TableCell>
                                        <TableCell align="right" colSpan={2}>
                                            {formatMoney(amountPaid || 0)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <Table
                            sx={{
                                "& th, & td": {
                                    border: "0.5px solid grey",
                                    fontSize: 10,
                                    padding: 0.3,
                                },
                            }}
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: "primary.main",
                                }}
                            >
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>Order Number</TableCell>
                                    <TableCell sx={{ color: "white" }}>Date Placed</TableCell>
                                    <TableCell sx={{ color: "white" }}>Date Delivered</TableCell>
                                    <TableCell sx={{ color: "white" }}>Customer Name</TableCell>
                                    <TableCell sx={{ color: "white" }}>Location</TableCell>
                                    <TableCell sx={{ color: "white" }}>Phone Number</TableCell>
                                    <TableCell sx={{ color: "white" }}>Product Cost</TableCell>
                                    <TableCell sx={{ color: "white" }}>Shipping Cost</TableCell>
                                    <TableCell sx={{ color: "white" }}>Transaction Ref</TableCell>
                                    <TableCell sx={{ color: "white" }}>Order Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((item, index) => {
                                    let dateDeliveredOrCancelled = "";
                                    const orderObj = item.order_status_tracking.find(orderItem => orderItem.order_status === "DELIVERED" || item.order_status === "RETURNED")
                                    if (orderObj !== undefined) {
                                        dateDeliveredOrCancelled = orderObj.created_at;
                                    }

                                    return (
                                        <TableRow key={index}>
                                            <TableCell scope="row">
                                                {item.id + getOrderNumberFromdate(item.created_at)}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {formatDate(item.created_at)}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {formatDate(dateDeliveredOrCancelled)}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {item.full_name}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {item.region}, {item.district}, {" "}
                                                {item.ward &&
                                                    <>
                                                        {item.ward},
                                                    </>
                                                } {item.street}.
                                            </TableCell>
                                            <TableCell scope="row">
                                                {item.phone_number}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {formatMoney(item.product_amount || 0)}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {formatMoney(item.shipping_cost || 0)}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {item.order_id}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {item.order_status}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Stack>
                </Container>
            }
        </Box>
    )
}

export default DownloadVendorInvoice