import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, SvgIcon, TextField, Typography } from '@mui/material'
import React from 'react'
import { postRequest } from '../../services/api-service'
import Lottie from "lottie-react"
import successAnimation from "../../animations/success_animation.json"
import failedAnimation from "../../animations/failed_animation.json"
import { useAuth } from '../../hooks/use-auth'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function MakePaymentDialog({
    open,
    handleClose,
    data,
    makePaymentUrl,
    verifyPaymentUrl
}) {
    const auth = useAuth();
    const [isSubmitting, setSubmitting] = React.useState(false)
    const [waitingPayment, setWaitingPayment] = React.useState(false)
    const [verifyPaymentComplete, setVerifyPaymentComplete] = React.useState({ complete: false, withouterrors: false })
    const [phoneNumber, setPhoneNumber] = React.useState("")
    const [amount, setAmount] = React.useState(0)
    const [error, setError] = React.useState("");

    const makePayment = () => {
        setSubmitting(true)
        postRequest(
            makePaymentUrl,
            {
                id: data?.id,
                user_id: auth?.user?.id,
                phone_number: phoneNumber,
                amount: parseInt(amount)
            },
            (data) => {
                setSubmitting(false)
                verifyPaymentDetails(data?.message)
            },
            (error) => {
                error?.response?.data?.message && setError(error.response.data.message[0])
                setSubmitting(false)
            }
        )
    }

    const verifyPaymentDetails = (orderId) => {
        setWaitingPayment(true)
        postRequest(
            verifyPaymentUrl,
            {
                id: data?.id,
                order_id: orderId
            },
            (data) => {
                setVerifyPaymentComplete({ complete: true, withouterrors: true })
            },
            (error) => {
                error?.response?.data?.message && setError(error.response.data.message[0])
                setWaitingPayment(false)
                setVerifyPaymentComplete({ complete: true, withouterrors: false })
            }
        )
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="data-feed-form-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    disabled={isSubmitting}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <SvgIcon fontSize='small'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogTitle>Make Payment</DialogTitle>
            {!waitingPayment && !verifyPaymentComplete.complete &&
                <>
                    <DialogContent>
                        <TextField
                            multiline
                            type="text"
                            label={"Phone Number"}
                            margin="normal"
                            fullWidth
                            value={phoneNumber}
                            onChange={(event) => {
                                setPhoneNumber(event.target.value)
                            }}
                        />
                        <TextField
                            multiline
                            type="text"
                            label={"Amount"}
                            margin="normal"
                            fullWidth
                            value={amount}
                            onChange={(event) => {
                                setAmount(event.target.value)
                            }}
                        />
                        <Typography
                            color="error"
                            sx={{
                                mt: 2,
                            }}
                        >
                            {error}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            onClick={makePayment}
                        >
                            {isSubmitting ?
                                "Loading..." :
                                `Submit`
                            }
                        </Button>
                    </DialogActions>
                </>
            }
            {waitingPayment && !verifyPaymentComplete.complete &&
                <DialogContent>
                    <Typography align='center' variant='h6'>
                        Waiting Payment From {phoneNumber} ...
                    </Typography>
                </DialogContent>
            }
            {verifyPaymentComplete.complete && verifyPaymentComplete.withouterrors &&
                <DialogContent>
                    <Lottie
                        animationData={successAnimation}
                        loop={true}
                    />
                </DialogContent>
            }
            {verifyPaymentComplete.complete && !verifyPaymentComplete.withouterrors &&
                <DialogContent>
                    <Typography
                        color="error"
                        align='center'
                        sx={{
                            mt: 2,
                        }}
                    >
                        {error}
                    </Typography>
                    <Lottie
                        animationData={failedAnimation}
                        loop={true}
                        size={20}
                    />
                </DialogContent>
            }
        </Dialog>
    )
}

export default MakePaymentDialog