import dayjs from "dayjs";

export const headers = { 'afya-sign-auth': `${process.env.REACT_APP_KEY}`, }
export const contentTypeJson = { 'Content-Type': `application/json`, }
export const contentTypeFormData = { 'Content-Type': `multipart/form-data`, }
export const ADMIN = "admin";
export const SMAIADMIN = "smai_admin";
export const SMAICASHIER = "smai_cashier";
export const SMAISECRETARY = "smai_secretary";
export const SMAIVENDOR = "smai_vendor";
export const SMAISUPERVISOR = "smai_supervisor";
export const SMAIINVENTORYMANAGER = "smai_inventory_manager";
export const SMAIDISPATCHMANAGER = "smai_dispatch_manager";
export const VENDOR = "vendor";
export const MIN_SIDE_NAV_WIDTH = 85;
export const SUB_SIDE_NAV_WIDTH = 195;
export const SIDE_NAV_WIDTH = 280;
export const CREATE = "Create";
export const UPDATE = "Update";
export const YES = "YES";
export const filterItems = [
    { label: "Today" },
    { label: "Yesterday" },
    { label: "Last 7 days" },
    { label: "This month" },
    { label: "All time" },
]
export const orderStatus = [
    { value: '', label: "All" },
    { value: 'RECEIVED', label: "Pending" },
    { value: 'NEEDTOCALLAGAIN', label: "Need To Call" },
    { value: 'CONFIRMED', label: "Confirmed" },
    { value: 'CANCELLED', label: "Cancelled" },
    { value: 'PROCESSING', label: "Processing" },
    { value: 'INTRANSIT', label: "In Transit" },
    { value: 'DELIVERED', label: "Delivered" },
    { value: 'RETURNED', label: "Returned" },
    { value: 'REFUNDED', label: "Refunded" },
    { value: 'ONLINE', label: "Cash Online" },
    { value: 'DELIVERY', label: "Cash On Delivery" },
    { value: 'COMPLETED', label: "Completed Payments" },
    { value: 'INCOMPLETE', label: "Incomplete Payments" },
    { value: 'PENDING', label: "Pending Payments" },
    { value: 'NEED TO BE DELIVERED TODAY', label: "Delivered Today" },
    { value: 'NEED TO BE DELIVERED', label: "Need To Be Delivered" }
]
export const vendorOrderStatus = [
    { value: '', label: "All" },
    { value: 'RECEIVED', label: "Pending" },
    { value: 'CONFIRMED', label: "Confirmed" },
    { value: 'CANCELLED', label: "Cancelled" },
    { value: 'PROCESSING', label: "Processing" },
    { value: 'INTRANSIT', label: "In Transit" },
    { value: 'DELIVERED', label: "Delivered" },
    { value: 'RETURNED', label: "Returned" },
    { value: 'OUTOFSTOCK', label: "Out Of Stock" },
]
export const ordersBasedOnPlatform = [
    { value: 'From App', label: "From App" },
    { value: 'From Web Portal', label: "From Web Portal" }
]
export const ordersBasedOnVendors = [
    { value: "Online Orders", label: "Online Orders" },
    { value: "Offline Orders", label: "Offline Orders" }
]
export const profileTabs = [
    { value: "Profile", label: "Profile" },
    { value: "Wallet", label: "Wallet" }
]
export const externalOrderStatus = [
    { value: '', label: "All" },
    { value: 'RECEIVED', label: "Pending" },
    { value: 'NEEDTOCALLAGAIN', label: "Need To Call" },
    { value: 'CONFIRMED', label: "Confirmed" },
    { value: 'CANCELLED', label: "Cancelled" },
    { value: 'PROCESSING', label: "Processing" },
    { value: 'INTRANSIT', label: "In Transit" },
    { value: 'DELIVERED', label: "Delivered" },
    { value: 'RETURNED', label: "Returned" },
    { value: 'DUPLICATED', label: "Duplicated" },
    { value: 'OUTOFSTOCK', label: "Out Of Stock" },
    { value: 'COMPLETED', label: "Completed Payments" },
    { value: 'INCOMPLETE', label: "Incomplete Payments" },
    { value: 'PENDING', label: "Pending Payments" }
]
export const orderStatusForPaymentVerification = [
    { value: '', label: "All" },
    { value: 'DELIVERED', label: "Delivered" },
    { value: 'ONLINE', label: "Cash Online" },
    { value: 'DELIVERY', label: "Cash On Delivery" },
    { value: 'COMPLETED', label: "Completed Payments" },
    { value: 'INCOMPLETE', label: "Incomplete Payments" },
    { value: 'PENDING', label: "Pending Payments" },
]
export const importedOrderStatusForPaymentVerification = [
    { value: '', label: "All" },
    { value: 'DELIVERED', label: "Delivered" },
    { value: 'COMPLETED', label: "Completed Payments" },
    { value: 'INCOMPLETE', label: "Incomplete Payments" },
    { value: 'PENDING', label: "Pending Payments" },
]
export const paymentsStatus = [
    { label: "Tranport Payments History" },
    { label: "Products Payments History" }
]
export const statusOfOrder = [
    { value: 'RECEIVED', label: "PENDING", },
    { value: 'NEEDTOCALLAGAIN', label: "NEED TO CALL AGAIN", },
    { value: 'CONFIRMED', label: "CONFIRM", },
    { value: 'PROCESSING', label: "PROCESSING", },
    { value: 'INTRANSIT', label: "ON TRANSIT", },
    { value: 'DELIVERED', label: "DELIVERED", },
    { value: 'CANCELLED', label: "CANCELLED", },
    { value: 'RETURNED', label: "RETURNED", },
    { value: 'REFUNDED', label: "REFUNDED", },
    { value: 'DUPLICATED', label: "DUPLICATED", },
    { value: 'OUTOFSTOCK', label: "OUT OF STOCK", },
]
export const paymentChannels = [
    { value: 'MPESA', label: "MPESA", },
    { value: 'AIRTEL MONEY', label: "AIRTEL MONEY", },
    { value: 'TIGO PESA', label: "TIGO PESA", },
    { value: 'HALO PESA', label: "HALO PESA", },
    { value: 'TPESA', label: "TPESA", },
    { value: 'NMB', label: "NMB", },
    { value: 'CRDB', label: "CRDB", },
    { value: 'NBC', label: "NBC", },
    { value: 'OTHER BANKS', label: "OTHER BANKS", },
    { value: 'CASH', label: "CASH", },
    { value: 'BINANCE ID', label: "BINANCE ID", },
]
export const filterOrderStatus = [
    { value: 'CONFIRMED', label: "CONFIRMED", },
    { value: 'NEEDTOCALLAGAIN', label: "NEED TO CALL AGAIN", },
    { value: 'CANCELLED', label: "CANCELLED", },
    { value: '', label: "ALL", }
]
export const orderPaymentStatus = [
    { value: 'COMPLETED', label: "COMPLETED", },
    { value: 'PENDING', label: "PENDING", },
]
export const filterOrderPaymentStatus = [
    { value: 'COMPLETED', label: "COMPLETED", },
    { value: 'PENDING', label: "PENDING", },
    { value: '', label: "ALL", }
]
export const todayTimeRange = {
    "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
}
export const yesterdayTimeRange = {
    "from": dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
}
export const lastSevenDaysTimeRange = {
    "from": dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
}
export const thisMonthTimeRange = {
    "from": dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss.SSS'),
}
export const allTimeTimeRange = {
    "from": dayjs('1970-01-01T00:00:00Z').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
};
export const productStatus = [
    {
        label: "All",
    },
    {
        label: "Available",
    },
    {
        label: "Unavailable",
    }
]
export const customTabsStyle = {
    borderRight: '1px solid #bfbfbf',
    borderLeft: '1px solid #bfbfbf',
    borderTop: '1px solid #bfbfbf'
}

export const getColumnName = (columns, columnName) => {
    const column = columns.find((column) => column === columnName);
    return column || "";
};

export const getOrderNumberFromdate = (inputDate) => {
    const date = new Date(inputDate);

    // const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;

    return `${year}`;
};

export function convertArrayOfProductToString(product) {
    let productstring = "";
    for (let index = 0; index < product.length; index++) {
        let productItem = product[index];
        productstring = productstring + " " + productItem.product_name + " [" + productItem.product_quantity + "]";
    }
    return productstring;
}

export function getPercenteage(value, total) {
    const percentage = value / total * 100;
    return Math.round(percentage || 0);
}

export function getRegionId(regions, regionName) {
    const region = regions.find((item) => item.region_name === regionName);
    return region?.id || 0;
}

export function getDistrictId(districts, districtName) {
    const district = districts.find((item) => item.district_name === districtName);
    return district?.id || 0;
}

export function getWardId(wards, wardName) {
    const ward = wards.find((item) => item.ward_name === wardName);
    return ward?.id || "";
}

export function isDarEsSalaam(region) {
    const lowerCaseRegion = region.toString().toLowerCase();
    const darEsSalaamKeywords = ["dar es salaam", "dar", "dsm"];

    return darEsSalaamKeywords.some(keyword => lowerCaseRegion.includes(keyword));
}

export const capitalizeFirstLetter = (str) => {
    return str
        .replace(/_/g, ' ')
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export function formatMoney(amount, currency = 'TZS') {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    }).format(amount).replace(/\.00$/, '');
}

export const getOrderStatus = (role, status) => {
    let newStatusOfOrder = role === SMAIADMIN || role === VENDOR ?
        statusOfOrder :
        role === SMAISECRETARY || role === SMAIINVENTORYMANAGER ?
            statusOfOrder.filter(item =>
                item.value !== "DELIVERED" &&
                item.value !== "RETURNED" &&
                item.value !== "REFUNDED" &&
                item.value !== "DUPLICATED"
            ) :
            role === SMAIDISPATCHMANAGER ?
                statusOfOrder.filter(item =>
                    item.value !== "DELIVERED" &&
                    item.value !== "REFUNDED"
                ) :
                role === SMAICASHIER ?
                    statusOfOrder.filter(item =>
                        item.value !== "RETURNED" &&
                        item.value !== "REFUNDED" &&
                        item.value !== "DUPLICATED"
                    ) :
                    []
    if (status === "INTRANSIT" && role !== SMAIADMIN && role !== VENDOR) {
        newStatusOfOrder = newStatusOfOrder.filter(item => item.value !== "CANCELLED");
    }
    const index = newStatusOfOrder.findIndex((item) => item.value === status);
    if (index !== -1 && role !== SMAIADMIN) {
        return newStatusOfOrder.slice(index + 1, newStatusOfOrder.length);
    } else {
        return newStatusOfOrder;
    }
};