import React from 'react'
import { Autocomplete, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, SvgIcon, TextField, Typography } from '@mui/material'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { SMAIVENDOR, YES, capitalizeFirstLetter, isDarEsSalaam } from '../../utils/constant'
import { authPostRequest } from '../../services/api-service'
import { createExternalOrderUrl, getAllUsersByRoleUrl } from '../../seed/url'
import { useAuth } from '../../hooks/use-auth'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DataFeedUploadDialog({
    open,
    handleClose,
    ordersData,
    setOrdersData,
}) {
    const auth = useAuth();
    const [isSubmitting, setSubmitting] = React.useState(false)
    const [mappingComplete, setMappingComplete] = React.useState(false)
    const [error, setError] = React.useState("");
    const [activeStep, setActiveStep] = React.useState(1);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const handleNext = () => {
        if (auth?.user?.role === SMAIVENDOR) {
            uploadOrders();
        } else {
            if (activeStep < 2) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            if (activeStep === 2) {
                uploadOrders();
            }
        }
    };

    const handlePrevious = () => {
        if (activeStep > 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }

    const uploadOrders = () => {
        setSubmitting(true)
        const inBoundShippingCost = auth?.user?.inbound_recharge_amount;
        const outBoundShippingCost = auth?.user?.outbound_recharge_amount;
        const inBoundReturnCost = auth?.user?.inbound_return_amount;
        const outBoundReturnCost = auth?.user?.outbound_return_amount;
        const columnItem = ordersData.mappedColumns.find(item => item.supportedColumn === "region");
        const regionKey = columnItem.originalColumn;
        const data = ordersData.rows
            .map((item) => {
                const newItem = {
                    product_vendor_id: auth?.user?.role === SMAIVENDOR ?
                        auth?.user?.id :
                        ordersData.vendorID,
                };
                ordersData.mappedColumns.forEach((column) => {
                    if (auth?.user?.role === SMAIVENDOR) {
                        if (column.originalColumn !== "id" && column.originalColumn !== "") {
                            newItem[column.supportedColumn] = column.supportedColumn === "product_amount" ||
                                column.supportedColumn === "product_quantity" ?
                                parseInt(item[column.originalColumn]) :
                                item[column.originalColumn]?.toString()?.trim();
                        }
                        if (column.supportedColumn === "product_amount" &&
                            auth?.user?.include_shipping_cost === YES) {
                            const shippingCost = isDarEsSalaam(item[regionKey]) ?
                                inBoundShippingCost : outBoundShippingCost
                            newItem[column.supportedColumn] = parseInt(item[column.originalColumn]) -
                                shippingCost
                        }
                        if (column.supportedColumn === "shipping_cost") {
                            newItem[column.supportedColumn] = isDarEsSalaam(item[regionKey]) ?
                                inBoundShippingCost : outBoundShippingCost
                        }
                        if (column.supportedColumn === "return_amount") {
                            newItem[column.supportedColumn] = isDarEsSalaam(item[regionKey]) ?
                                inBoundReturnCost : outBoundReturnCost
                        }
                    } else {
                        if (column.originalColumn !== "id" && column.originalColumn !== "") {
                            newItem[column.supportedColumn] = column.supportedColumn === "product_amount" ||
                                column.supportedColumn === "shipping_cost" ||
                                column.supportedColumn === "product_quantity" ||
                                column.supportedColumn === "return_amount" ?
                                parseInt(item[column.originalColumn]) :
                                item[column.originalColumn]?.toString()?.trim();
                        }
                    }
                });
                return newItem;
            });
        authPostRequest(
            createExternalOrderUrl,
            {
                "data": data,
            },
            (data) => {
                setSubmitting(false)
                handleClose()
            },
            (error) => {
                error?.response?.data?.message && setError(error.response.data.message[0])
                setSubmitting(false)
            }
        )
    }

    React.useEffect(() => {
        const isMappingComplete = ordersData.mappedColumns.filter(item => item.columnRequired).every((column) => column.originalColumn !== "");
        setMappingComplete(isMappingComplete);
    }, [ordersData])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="data-feed-form-dialog"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    disabled={isSubmitting}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <SvgIcon fontSize='small'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogTitle>Data Feed Upload Orders</DialogTitle>
            <DialogContent>
                {activeStep === 1 &&
                    <Grid container spacing={2}>
                        {ordersData?.mappedColumns.map((column, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Grid item sm={12} xs={12} lg={6}>
                                        <TextField
                                            multiline
                                            disabled
                                            type="text"
                                            label="Supported Column"
                                            margin="normal"
                                            fullWidth
                                            value={capitalizeFirstLetter(column.supportedColumn)}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} lg={6}>
                                        <TextField
                                            select
                                            margin='normal'
                                            label="Data Feed Column"
                                            value={column.originalColumn}
                                            onChange={(event) => {
                                                const newColumns = ordersData?.mappedColumns;
                                                newColumns[index].originalColumn = event.target.value;
                                                const newOrdersData = {
                                                    ...ordersData,
                                                    mappedColumns: newColumns,
                                                };
                                                setOrdersData(newOrdersData);
                                            }}
                                            fullWidth
                                        >
                                            {ordersData?.originalColumns.map((originalColumn) => {
                                                let mappedTo = "";
                                                const originalColumnIndex = ordersData?.mappedColumns.findIndex(
                                                    (item) => item.originalColumn === originalColumn,
                                                );
                                                if (originalColumnIndex !== -1) {
                                                    mappedTo = ordersData?.mappedColumns[originalColumnIndex].supportedColumn;
                                                }

                                                return (
                                                    <MenuItem
                                                        key={originalColumn}
                                                        value={originalColumn}
                                                        disabled={mappedTo !== ""}
                                                    >
                                                        {originalColumn}
                                                        {mappedTo !== "" && ` mapped to (${mappedTo})`}
                                                    </MenuItem>
                                                )
                                            })}
                                            <MenuItem
                                                value=""
                                            >
                                                Reset Mapping
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                }
                {activeStep === 2 &&
                    <Autocomplete
                        options={options}
                        getOptionLabel={(option) =>
                            `${option["userName"].toString()}`
                        }
                        filterOptions={(x) => x}
                        noOptionsText={isLoading ? "Loading..." : "No items"}
                        includeInputInList
                        filterSelectedOptions
                        onChange={(event, value) => {
                            if (value) {
                                const newOrdersData = {
                                    ...ordersData,
                                    vendorID: value.id,
                                };
                                setOrdersData(newOrdersData);
                            }
                        }}
                        renderOption={(props, option) => {

                            return (
                                <li {...props}>
                                    <List sx={{ width: "100%" }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar src={option["profileImage"]} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${option["userName"]}`}
                                            />
                                        </ListItem>
                                    </List>
                                </li>
                            )
                        }}
                        onInputChange={() => setOptions([])}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Vendors"
                                color='secondary'
                                fullWidth
                                margin='normal'
                                value={value}
                                onChange={(event) => {
                                    setIsLoading(true)
                                    setValue(event.target.value)
                                    authPostRequest(
                                        getAllUsersByRoleUrl,
                                        {
                                            query: event.target.value,
                                            role: "smai_vendor",
                                            sort: "id desc",
                                            limit: 10,
                                            page: 1
                                        },
                                        (data) => {
                                            setOptions(data.results)
                                            setIsLoading(false)
                                        },
                                        (error) => {
                                            error?.response?.data?.message && setError(error.response.data.message[0])
                                            setIsLoading(false)
                                        }
                                    )
                                }}
                                onFocus={(event) => {
                                    setIsLoading(true)
                                    setValue(event.target.value)
                                    authPostRequest(
                                        getAllUsersByRoleUrl,
                                        {
                                            query: event.target.value,
                                            role: "smai_vendor",
                                            sort: "id desc",
                                            limit: 10,
                                            page: 1
                                        },
                                        (data) => {
                                            setOptions(data.results)
                                            setIsLoading(false)
                                        },
                                        (error) => {
                                            error?.response?.data?.message && setError(error.response.data.message[0])
                                            setIsLoading(false)
                                        }
                                    )
                                }}
                            />
                        )}
                    />
                }
                <Typography
                    color="error"
                    sx={{
                        mt: 2,
                    }}
                >
                    {error}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                {activeStep > 1 &&
                    <Button
                        onClick={handlePrevious}
                    >
                        Previous
                    </Button>
                }
                {mappingComplete &&
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        onClick={handleNext}
                    >
                        {isSubmitting ?
                            "Loading..." :
                            auth?.user?.role === SMAIVENDOR ?
                                "Upload" :
                                activeStep < 2 ?
                                    "Next" :
                                    `Upload`
                        }
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default DataFeedUploadDialog