import { createBrowserRouter } from "react-router-dom";
import Layout from "../layouts/dashboard/Layout";
import { AuthLayout } from "../layouts/auth/auth-layout";
import Login from "../pages/Login/Login";
import { ImportedOrders, Orders } from "../pages/Orders";
import Secreteries from "../pages/Users/Secreteries";
import Administrators from "../pages/Users/Administrators";
import Vendors from "../pages/Users/Vendors";
import OrdersPaymentVerification from "../pages/PaymentsVerification";
import Cashiers from "../pages/Users/Cashiers";
import ImportedOrdersPaymentVerification from "../pages/PaymentsVerification/ImportedOrdersPaymentVerification";
import { ImportedOrdersInvoices, OrdersInvoices } from "../pages/Invoices";
import VendorInventory from "../pages/Inventory/VendorInventory";
import VendorOrders from "../pages/Orders/VendorOrders";
import Inventory from "../pages/Inventory/Inventory";
import Dashboard from "../pages/Dashboard/Dashboard";
import Account from "../pages/Account/Account";
import VendorTrackOrders from "../pages/Orders/VendorTrackOrders";
import Wallet from "../pages/Wallet/Wallet";
import Billing from "../pages/Billing/Billing";
import Notifications from "../pages/Notifications/Notifications";
import VendorLayout from "../layouts/VendorLayout";
import TransactionsHistory from "../pages/TransactionsHistory/TransactionsHistory";
import Expenses from "../pages/Inventory/Expenses";
import PrintingOrders from "../pages/Printing/PrintingOrders";
import PrintingImportedOrders from "../pages/Printing/PrintingImportedOrders";
import DispatchManagers from "../pages/Users/DispatchManagers";
import InventoryManagers from "../pages/Users/InventoryManagers";
import Supervisors from "../pages/Users/Supervisors";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard />,
    },
    {
        path: "orders/orders",
        element: <Layout><Orders /></Layout>,
    },
    {
        path: "orders/imported-orders",
        element: <Layout><ImportedOrders /></Layout>,
    },
    {
        path: "users/vendors",
        element: <Layout><Vendors /></Layout>,
    },
    {
        path: "users/confirming-agents",
        element: <Layout><Secreteries /></Layout>,
    },
    {
        path: "users/dispatch-managers",
        element: <Layout><DispatchManagers /></Layout>,
    },
    {
        path: "users/cashier-agents",
        element: <Layout><Cashiers /></Layout>,
    },
    {
        path: "users/inventory-managers",
        element: <Layout><InventoryManagers /></Layout>,
    },
    {
        path: "users/supervisors",
        element: <Layout><Supervisors /></Layout>,
    },
    {
        path: "users/administrators",
        element: <Layout><Administrators /></Layout>,
    },
    {
        path: "inventory",
        element: <Layout><Inventory /></Layout>,
    },
    {
        path: "expenses",
        element: <Layout><Expenses /></Layout>,
    },
    {
        path: "printing/orders-from-app",
        element: <Layout><PrintingOrders /></Layout>,
    },
    {
        path: "printing/orders-from-vendors",
        element: <Layout><PrintingImportedOrders /></Layout>,
    },
    {
        path: "payments/verification-orders-from-app",
        element: <Layout><OrdersPaymentVerification /></Layout>,
    },
    {
        path: "payments/verification-orders-from-vendors",
        element: <Layout><ImportedOrdersPaymentVerification /></Layout>,
    },
    {
        path: "invoices/orders-from-app",
        element: <Layout><OrdersInvoices /></Layout>,
    },
    {
        path: "invoices/orders-from-vendors",
        element: <Layout><ImportedOrdersInvoices /></Layout>,
    },
    {
        path: "vendor/inventory",
        element: <VendorLayout><VendorInventory /></VendorLayout>,
    },
    {
        path: "vendor/track-orders",
        element: <VendorLayout><VendorTrackOrders /></VendorLayout>,
    },
    {
        path: "vendor/import-orders",
        element: <VendorLayout><VendorOrders /></VendorLayout>,
    },
    {
        path: "billing",
        element: <VendorLayout><Billing /></VendorLayout>,
    },
    {
        path: "wallet",
        element: <VendorLayout><Wallet /></VendorLayout>,
    },
    {
        path: "transaction-history",
        element: <VendorLayout><TransactionsHistory /></VendorLayout>,
    },
    {
        path: "notifications",
        element: <VendorLayout><Notifications /></VendorLayout>,
    },
    {
        path: "settings",
        element: <VendorLayout><Account /></VendorLayout>,
    },
    {
        path: "login",
        element: <AuthLayout><Login /></AuthLayout>,
    },
]);