import React from "react";
import {
    Box,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    SvgIcon,
    Typography,
} from "@mui/material";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {
    formatMoney,
    getOrderNumberFromdate,
} from "../../utils/constant";
import UserIcon from "@heroicons/react/24/outline/UserIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import CalendarDaysIcon from "@heroicons/react/24/outline/CalendarDaysIcon";
import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";
import TruckIcon from "@heroicons/react/24/outline/TruckIcon";
import { formatDate } from "../../utils/date-formatter";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function VendorViewOrder({ open, handleClose, data }) {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="data-feed-form-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <SvgIcon fontSize="small">
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogTitle>
                Information For Order No.{" "}
                {data?.id + getOrderNumberFromdate(data?.created_at)}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        lg={12}
                    >
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <SvgIcon fontSize="small">
                                <UserIcon />
                            </SvgIcon>
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                Full Name: {data?.full_name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <SvgIcon fontSize="small">
                                <MapPinIcon />
                            </SvgIcon>
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                Shipping Address:{" "}
                                {`${data?.region}, ${data?.district}, ${data?.ward} ${data?.ward !== "" ? "," : ""} ${data?.street}`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <SvgIcon fontSize="small">
                                <PhoneIcon />
                            </SvgIcon>
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                Phone Number: {data?.phone_number}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <SvgIcon fontSize="small">
                                <CalendarDaysIcon />
                            </SvgIcon>
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                Date: {formatDate(data?.created_at)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1
                            }}
                        >
                            <SvgIcon fontSize="small">
                                <TruckIcon />
                            </SvgIcon>
                            <Typography variant="body1" sx={{ mx: 2 }}>
                                Order Status:
                            </Typography>
                            <Chip
                                color={
                                    data?.order_status ===
                                        "DELIVERED"
                                        ? "success"
                                        : "warning"
                                }
                                label={data?.order_status}
                                sx={{
                                    width: 110,
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1
                            }}
                        >
                            <SvgIcon fontSize="small">
                                <BanknotesIcon />
                            </SvgIcon>
                            <Typography variant="body1" sx={{ mx: 2 }}>
                                Payment Status:
                            </Typography>
                            <Chip
                                color={
                                    data?.payment_status ===
                                        "COMPLETED"
                                        ? "success"
                                        : "warning"
                                }
                                label={data?.payment_status}
                                sx={{
                                    width: 110,
                                }}
                            />
                        </Box>
                        {data?.cart && (
                            <Box sx={{ mt: 5 }}>
                                <Typography variant="h6">Products</Typography>
                                <Typography>{data?.cart} [{data?.product_quantity}]</Typography>
                            </Box>
                        )}
                        <Typography sx={{ my: 1 }}>
                            Total Product Cost: {formatMoney(data?.product_name ? data?.discount_amount : data?.product_amount)}
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            Shipping Cost: {formatMoney(data?.shipping_cost)}
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            Return Cost: {formatMoney(data?.return_amount)}
                        </Typography>
                        <Typography>
                            Total Cost:{" "}
                            {formatMoney(data?.product_amount + data?.shipping_cost)}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default VendorViewOrder;
