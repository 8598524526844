import React from 'react';
import { Avatar, Box, Chip, Drawer, IconButton, SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import PropTypes from 'prop-types';

export const ViewPaymentSideNav = (props) => {
    const { open, onClose } = props;

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.100',
                    width: 300
                }
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2,
                    borderBottom: "2px solid grey",
                }}
            >
                <Typography variant='h6'>App Subscription</Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                        onClose()
                    }}
                    aria-label="close"
                >
                    <SvgIcon fontSize='small'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    flexDirection: "column"
                }}
            >
                <Avatar
                    sx={{
                        cursor: 'pointer',
                        height: 70,
                        width: 70
                    }}
                    alt="User Profile"
                    src={"https://www.testserver.doctors.afyachap.com/users/profileImages/male.png"}
                />
                <Typography variant='h6'>
                    John Doe
                </Typography>
            </Box>
            <Table
                sx={{
                    '& th, & td': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell>ORDER ID:</TableCell>
                        <TableCell>TR-A000-0041601</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CHANNEL:</TableCell>
                        <TableCell>MPESA-TZ</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Reference:</TableCell>
                        <TableCell>0671001624</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CREATED AT:</TableCell>
                        <TableCell>2023-08-07 23:44:30</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Selcom Status:</TableCell>
                        <TableCell>
                            <Chip
                                color={'success'}
                                label={"COMPLETED"}
                                sx={{
                                    width: 110
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Drawer>
    );

};

ViewPaymentSideNav.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};